<template>
  <CustomSelectComponent
      :no-label="noLabel"
      @input="handleInput" :value="value" add-class="mb-3" :options="options" placeholder="Status"/>
</template>

<script>
import {getStatus} from "../../service/LocationService";
import {mapGetters} from "vuex";
import CustomSelectComponent from "./CustomSelectComponent";
import {mapState, mapMutations} from "vuex"

export default {
  name: "SelectStatusComponent",
  components: {CustomSelectComponent},
  props: {
    value: {
      type: [Number, String],
      default: ''
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    isAfter: {
      type: Boolean, default: false
    },
    isFilter: {
      type: Boolean, default: false
    }
  },
  async mounted() {
    if (this.status.length) {
      this.statusOptions = this.status
    } else {
      let res = await getStatus({})
      let options = res.data
      this.statusOptions = options
      this.setStatus(options)
    }
  },
  data() {
    return {
      statusOptions: []
    }
  },
  computed: {
    ...mapState('LocationStore', ['status']),
    ...mapGetters('UserStore', ['isAdmin']),
    options() {
      if (this.isFilter) {
        if (this.isAdmin) {
          return this.statusOptions
        } else {
          const {user} = this.query
          if (user) {
            return this.statusOptions
          } else {
            return this.statusOptions.filter((v) => {
              return v.id !== 3
            })
          }
        }
      }

      if (this.isAfter) {
        return this.statusOptions.filter((v) => {
          return v.id > Number(this.value)
        })
      }

      return this.statusOptions
    },
    query() {
      return this.$route.query
    },
  },
  methods: {
    ...mapMutations('LocationStore', ['setStatus']),
    handleInput(e) {
      this.$emit('input', this.statusOptions.find(item => {
        return String(item.id) === String(e)
      }))
    }
  },
}
</script>

<style scoped>

</style>
