import Http from "../util/Http";

const apiUrl = process.env.VUE_APP_API_URL

export const Login = (data) => {
    return Http.post(`${apiUrl}/user/auth`, data)
}

export const Register = (data) => {
    return Http.post(`${apiUrl}/user`, data)
}

export const UserProfile = () => {
    return Http.get(`${apiUrl}/user/me`, {})
}

export const ActivateAccount = (token) => {
    return Http.post(`${apiUrl}/user/activate/account`, { token })
}

export const ResetPassword = (data) => {
    return Http.post(`${apiUrl}/user/send/forgot/web`, data)
}

export const ConfirmResetPassword = (data) => {
    return Http.post(`${apiUrl}/user/forgot/changePass`, data)
}

export const updateUser = (data) => {
  return Http.post(`${apiUrl}/user/update`, data)
}

export const getListUser = (data) => {
  return Http.get(`${apiUrl}/user`, data)
}

export const createUserAdmin = (data) => {
  return Http.post(`${apiUrl}/user/create`, data)
}

export const getUserById = (id) => {
    return Http.get(`${apiUrl}/user/${id}`, {})
}

export const loginSocial = (data) => {
  let url = `${apiUrl}/user/auth/sosmed`
  return Http.post(url, data)
}

export const validateCrossToken = (data) => {
  const url =`${apiUrl}/user/validateToken`
  return Http.post(url, data)
}
