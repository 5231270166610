<template>
  <div class="min-vh-100 w-50 mx-auto d-flex flex-column justify-content-center">
    <div v-if="!successRegister" class="card w-75 p-4 mx-auto bg-white-transparent shadow">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 text-center mb-3">
            <DynamicLogoComponent />
          </div>

          <div class="col-sm-12 mb-3">
            <p class="font-weight-bold text-left">Akun Google atau facebook belum terdaftar.</p>

            <form @submit.prevent="doRegister">
              <div class="form-group">
                <CustomInputPillComponent v-model="registerData.username"
                                          :is-with-label="true"
                                          :is-required="true"
                                          label="Nama Pengguna" />
              </div>

              <div class="form-group">
                <CustomInputPillComponent v-model="registerData.fullname"
                                          :is-with-label="true"
                                          :is-required="true"
                                          label="Nama Lengkap" />
              </div>
              <div class="form-group">
                <CustomInputPillComponent v-model="registerData.institusi"
                                          :is-with-label="true"
                                          :is-required="true"
                                          label="Organisasi / Institusi" />
              </div>
              <div class="form-group mt-3">
                <div class="form-row">
                  <div class="col-sm-6 text-left">
                    Sudah punya akun ? <br>
                    <router-link class="text-primary" :to="{ name: 'Login' }"><strong>Masuk</strong></router-link>
                  </div>
                  <div class="col-sm-6 text-right">
                    <button type="submit" class="btn btn-sm btn-custom-primary rounded-pill w-75">
                      Daftar
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="card w-75 p-4 mx-auto bg-white-transparent shadow">
      <div class="card-body">
        <h4 class="text-custom-primary mb-3 font-weight-bold">Pendaftaran Berhasil</h4>

        <p class="mb-3">
          Pendaftaran anda akan diproses dan anda akan menerima email konfirmasi untuk masuk
        </p>

        <router-link :to="{ name: 'Login' }" class="btn btn-custom-primary rounded-pill px-4">
          Ok
        </router-link>
      </div>
    </div>

    <div v-if="isLoading" class="loading-fullscreen">
      <LoadingComponent />
    </div>
  </div>
</template>
<script>
import LoadingComponent from "../../../components/general/LoadingComponent";
import SlideAnimationComponent from "../../../components/SlideAnimationComponent";
import FadeTransitionComponent from "../../../components/FadeTransitionComponent";
import DynamicLogoComponent from "../../../components/general/DynamicLogoComponent";
import CustomInputPillComponent from "../../../components/input/CustomInputPillComponent";
import CustomInputComponent from "../../../components/input/CustomInputComponent";
import {loginSocial, Register, UserProfile} from "../../../service/AuthService";
import {validateEmail} from "../../../util/ValidateInput";
import moment from "moment";
import {mapMutations} from "vuex";
const date = moment().toISOString()
export default {
  name: 'UserRegisterSocialComponent',
  components: {
    LoadingComponent,
    SlideAnimationComponent,
    FadeTransitionComponent, DynamicLogoComponent, CustomInputPillComponent, CustomInputComponent},
  data() {
    return {
      registerData: {
        username: '',
        fullname: '',
        email: '',
        institusi: '',
        password: date,
        rePassword: date,
        ref: 'web'
      },
      successRegister: false,
      isLoading: false
    }
  },
  mounted() {
    const {data} = this.$route.params
    if(!data) this.$router.push('/register')
    this.registerData.email = data.email
    const {facebookId, googleId} = data
    if(facebookId) this.registerData.facebookId = facebookId
    if(googleId) this.registerData.googleId = googleId
  },
  methods: {
    ...mapMutations('UserStore', ['setUserData']),
    async doRegister() {
      this.isLoading = true

      if (!this.validateEmail()) {
        await this.$store.dispatch('NotificationStore/openNotification', {
          message: 'Email tidak valid',
          type: 'warning'
        })
        this.isLoading = false

        return false
      }

      try {
        let res = await Register(this.registerData)

        if (res) {
          const loginResponse = await loginSocial(this.$route.params.data)
          if(loginResponse && loginResponse.data) {
            localStorage.setItem('accessToken', loginResponse.data.token)
            setTimeout(async () => {
              await this.doGetUserData()
              await this.$router.push({name: 'Dashboard'})
            }, 500)
          }
        }
      } catch (e) {
        let messages = e.response.data.message
        if (typeof messages === 'object') {
          messages = messages.join('<br>')
        }

        await this.$store.dispatch('NotificationStore/openNotification', {
          message: messages,
          type: 'error'
        })
      }

      this.isLoading = false
    },
    validateEmail() {
      return validateEmail(this.registerData.email)
    },
    async doGetUserData() {
      try {
        const res = await UserProfile()
        if (res && res.data) {
          this.setUserData(res.data)
        }
      } catch (e) {
        console.log(e)
        await this.$store.dispatch('NotificationStore/openNotification', {
          message: e.response.data.message,
          type: 'error'
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>

.login-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

</style>
