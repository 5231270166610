<template>
  <div>
    <select
        @input="handleChange($event.target.value)"
        class="form-control border-0">
      <option value="">Pilih Layer</option>
      <option v-for="(item, index) in layerOptions"
              :selected="item.id === Number(layerId)"
              :value="item.id">{{ item.name }}</option>
    </select>
  </div>
</template>
<script>
import {getLayerMaps} from "../../../service/ReportService";
import {mapMutations, mapState} from "vuex";

export default {
  name: 'LayerSelectorComponent',
  data() {
    return {}
  },
  mounted() {
    this.getLayer()
  },
  computed: {
    ...mapState('MapsStore', ['layerOptions', 'activeLayer']),
    query() {
      return this.$route.query
    },
    layerId() {
      if(this.query && this.query.layerId) {
        const find = this.layerOptions.find((v) => {
          return v.id === Number(this.query.layerId)
        })
        this.setActiveLayer(find)
        return this.query.layerId
      }else {
        this.setActiveLayer(null)
      }
      return ''
    }
  },
  methods: {
    ...mapMutations('MapsStore', ['setLayerOptions', 'setActiveLayer']),
    async getLayer() {
      try {
        const res = await getLayerMaps()
        if (res) this.setLayerOptions(res.data)
      } catch (e) {

      }
    },
    handleChange(e) {
      const find = this.layerOptions.find((v) => {
        return v.id === e
      })
      this.setActiveLayer(find)

      this.$router.push({
        name: 'Dashboard',
        query: {
          ...this.query,
          ...{
            layerId: e
          }
        }
      }).catch((e) => {})
    }
  }

}
</script>
<style lang="scss" scoped></style>
