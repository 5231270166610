<template>
	<div
		:class="[isShowSidebar ? 'active' : '']"
		class="report-info-panel">
		<div class="row">
			<div class="col-auto d-flex justify-content-between align-items-center border-right border-white">
				<div class="mx-1">
					<h2 class="m-0">{{ statisticReport.laporan }}</h2>
				</div>
				<div class="text-left mx-1">
					Total <br> Aduan
				</div>
			</div>
			<div class="col-auto d-flex justify-content-between align-items-center">
				<div class="mx-1">
					<img src="../../assets/images/map-marker-yellow.png" class="img-fluid w-75">
				</div>
				<div class="text-left mx-1">
					<h2 class="m-0">{{ statisticReport.laporanBaru }}</h2>
				</div>
				<div class="text-left mx-1">
					Aduan <br> Baru
				</div>
			</div>
			<div class="col-auto d-flex justify-content-between align-items-center">
				<div class="mx-1">
					<img src="../../assets/images/map-marker-red.png" class="img-fluid w-75">
				</div>
				<div class="text-left mx-1">
					<h2 class="m-0">{{ statisticReport.laporanVerifikasi }}</h2>
				</div>
				<div class="text-left mx-1">
					Aduan <br> Terverifikasi
				</div>
			</div>
			<div class="col-auto d-flex justify-content-between align-items-center">
				<div class="mx-1">
					<img src="../../assets/images/map-marker-blue.png" class="img-fluid w-75">
				</div>
				<div class="text-left mx-1">
					<h2 class="m-0">{{ statisticReport.laporanPenanganan }}</h2>
				</div>
				<div class="text-left mx-1">
					Aduan dalam <br> Penanganan
				</div>
			</div>
			<div class="col-auto d-flex justify-content-between align-items-center">
				<div class="mx-1">
					<img src="../../assets/images/map-marker-green.png" class="img-fluid w-75">
				</div>
				<div class="text-left mx-1">
					<h2 class="m-0">{{ statisticReport.laporanSelesai }}</h2>
				</div>
				<div class="text-left mx-1">
					Aduan <br> Selesai
				</div>
			</div>
		</div>
	</div>

</template>
<script>
import {statisticAdminReport} from "../../service/ReportService";
import {mapMutations, mapState} from "vuex";

export default {
	name: 'ReportStatisticAdminComponent',
	computed: {
		...mapState('SidebarStore', ['isShowSidebar']),
		...mapState('ReportStore', ['statisticReport'])
	},
	methods: {
		...mapMutations('ReportStore', ['setStatisticReport']),
		async getStatistic() {
			try {
				let res = await statisticAdminReport({})

				if (res && res.data) {
					this.setStatisticReport(res.data)
				}
			} catch (e) {
				this.$store.commit('NotificationStore/openNotification', {
					type: 'error',
					message: 'Error'
				})
			}
		}
	},
	mounted() {
		this.getStatistic()
	},
}
</script>
<style lang="scss" scoped>
p {
	margin-bottom: 0;
}

.report-info-panel {
	position: absolute;
	width: 1000px;
	padding: 30px;
	margin: 40px 70px;
	color: white;
	border-radius: 7px;
	bottom: 0;
	background: rgba(37,155,120,0.8);
	left: 0;
	right: 0;
	&.active {
		left: 400px
	}
}
</style>
