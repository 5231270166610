<template>
	<div class="position-relative">
		<div class="px-4 py-3 shadow">
			<h5 class="text-custom-primary d-flex justify-content-between align-items-center">
				<strong>Pengaduan Terbaru</strong>
				<a @click.prevent="closeSidebar" class="cursor-pointer">
					<span><i class="fa fa-long-arrow-alt-left"></i></span>
				</a>
			</h5>
		</div>

		<div class="px-4 py-3 report-list-container">
			<SidebarReportItemComponent v-for="(val,key) in reports" :key="key" :data="val"/>

			<div v-if="isShowAutoLoad" class="mb-3 text-center" style="margin-bottom: 150px">
				<button v-if="!isLoading" @click.prevent="getReportList"
				     class="btn btn-sm rounded-pill btn-yellow px-3 font-weight-bold">
					Muat lebih banyak
				</button>
				<div class="w-100 mx-auto" v-else>
					<LoadingComponent :is-small="true"/>
				</div>
			</div>
		</div>

		<div class="row footer-sidebar-list">
			<div class="col-sm-12 mb-3">
				<router-link :to="{name:'ReportList'}"
				             class="btn btn-custom-primary rounded-pill mr-2 font-weight-bold">
					Lihat Semua
				</router-link>
				<button @click.prevent="openCreateReport" class="btn btn-yellow rounded-pill font-weight-bold">
					<span><i class="fas fa-bullhorn mr-2"></i></span>
					Buat Pengaduan
				</button>
			</div>
		</div>

	</div>
</template>

<script>
import {mapState, mapMutations, mapActions, mapGetters} from "vuex";
import {getReport} from "../../../service/ReportService";
import SidebarReportItemComponent from "./SidebarReportItemComponent";
import LoadingComponent from "../../../components/general/LoadingComponent";

export default {
	name: "SidebarListReportComponent",
	components: {LoadingComponent, SidebarReportItemComponent},
	data() {
		return {
			isLoading: false
		}
	},
	computed: {
    ...mapState('UserStore', ['userData']),
		...mapState('ReportStore', ['offset', 'limit', 'totalData']),
		...mapGetters('ReportStore', ['reports']),
		isShowAutoLoad() {
			return Number(this.totalData) > Number(this.reports.length)
		},
		query() {
			return this.$route.query
		}
	},
	methods: {
		...mapMutations('ReportStore', ['setReports', 'resetReport', "setOffset", 'setMarkerActive']),
		...mapActions('NotificationStore', ['openNotification']),
		...mapMutations('SidebarStore', ['setIsCreateReport', 'setIsShowReportList', 'setIsShowSidebar']),
		async getReportList() {
			this.isLoading = true
			try {
				const parameter = {
					filter: {}
				}

        if (this.query && Object.keys(this.query).length) {
          this.resetReport()
          const {categoryId} = this.query
          if (categoryId) {
            parameter.filter['where[reportCategory][id]'] = categoryId
          }
        }

				parameter.filter = {
					...parameter.filter,
					...{
						take: this.limit,
						skip: this.offset
					}
				}

				const res = await getReport(parameter)
				if (res && res.data && res.data.data.length) {
					this.setReports(res.data)
					this.setOffset(this.offset + this.limit)
				}
			} catch (e) {
				await this.openNotification({
					message: e.response.data.message,
					type: 'error'
				})
			}
			this.isLoading = false
		},
		openCreateReport() {
      if(this.userData) {
        this.setIsCreateReport(true)
        this.setIsShowReportList(false)
      }else {
        this.$router.push({name: 'Login'})
      }

		},
		closeSidebar() {
			this.setIsShowReportList(false)
			this.setIsShowSidebar(false)
		}
	},
	mounted() {
		this.setMarkerActive(true)
	}
}
</script>

<style scoped lang="scss">
h5 {
	margin-bottom: 0;
}

.report-list-container {
	overflow-y: auto;
	overflow-x: hidden;
	margin-bottom: 50px;
}

.footer-sidebar-list {
	position: fixed;
	bottom: 0;
	left: 0;
	height: 70px;
	margin-left: auto;
	margin-right: auto;
	background-color: white;
	width: 440px;
	padding: 1rem;
	border-top: 1px solid #ccc;
}
</style>
