<template>
  <div v-if="data" class="container-fluid">
    <form @submit.prevent="handleSubmit">
      <div class="row py-5">
        <div class="col-6 text-left font-weight-bold">
          ID {{ data && data.id ? data.id : '-' }}
        </div>
        <div class="col-6 text-right">
          <button class="btn btn-yellow btn-sm btn-primary rounded-pill px-3">
            <i class="fa fa-save"></i> Simpan
          </button>
        </div>
        <div class="col-12">
          <hr>
        </div>
        <div class="col-6 text-left">
          <div class="form-group text-blue font-weight-bold">
            DATA PROFILE
          </div>
          <div class="row">
            <div class="col-8">
              <div class="form-group">
                <CustomInputComponent
                    :value="data.username"
                    :read-only="true"
                    placeholder="Nama Pengguna"/>
              </div>
              <div class="form-group">
                <CustomInputComponent
                    :value="data.email"
                    :read-only="true"
                    :type="`email`"
                    placeholder="Email"/>
              </div>
            </div>
            <div class="col-4">
              <UserAvatarComponent
                  :image="data.avatar"
                  @uploaded="data.avatar = $event"
              />
            </div>
          </div>
          <div class="form-group">
            <CustomInputComponent
                @input="data.mobile = $event"
                :value="data.mobile"
                :read-only="false"
                :type="`number`"
                placeholder="Nomor Ponsel"/>
          </div>
          <div class="form-group">
            <SelectInstitutionComponent
                @input="data.institusi = $event"
                :value="data.institusi && data.institusi.id ? data.institusi.id : ''"
            />
          </div>
          <div class="form-group">
            <CustomInputComponent
                :value="data.institusiName"
                @input="data.institusiName = $event"
                :read-only="false"
                :type="`text`"
                placeholder="Nama Instansi"/>
          </div>
          <div class="form-group">
            <CustomInputComponent
                @input="data.address = $event"
                :value="data.address"
                :read-only="false"
                :type="`text`"
                placeholder="Alamat Instansi"/>
          </div>
        </div>
        <div class="col-6 text-left">
          <div class="form-group text-blue font-weight-bold">
            Kata Sandi
          </div>
          <div class="form-group">
            <CustomInputComponent
                @input="data.oldPassword = $event"
                :value="data.oldPassword"
                :read-only="false"
                :type="`password`"
                placeholder="Kata Sandi Lama"/>
          </div>
          <div class="form-group">
            <CustomInputComponent
                @input="data.password = $event"
                :value="data.password"
                :read-only="false"
                :type="`password`"
                placeholder="Kata Sandi Baru"/>
          </div>
          <div class="form-group">
            <CustomInputComponent
                @input="confirmPassword = $event"
                :value="confirmPassword"
                :read-only="false"
                :type="`password`"
                placeholder="Konfirmasi kata sandi baru"/>
          </div>
        </div>
      </div>
    </form>
  </div>
  <LoadingComponent v-else/>
</template>
<script>
import {mapState, mapMutations} from 'vuex'
import LoadingComponent from "../../../components/general/LoadingComponent";
import CustomInputComponent from "../../../components/input/CustomInputComponent";
import UserAvatarComponent from "./components/UserAvatarComponent";
import SelectInstitutionComponent from "../../../components/input/SelectInstitutionComponent";
import {updateUser, UserProfile} from "../../../service/AuthService";

export default {
  name: 'EditProfileScreen',
  components: {SelectInstitutionComponent, UserAvatarComponent, CustomInputComponent, LoadingComponent},
  data() {
    return {
      data: null,
      confirmPassword: '',
      isLoading: false
    }
  },
  computed: {
    ...mapState('UserStore', ['userData'])
  },
  methods: {
    ...mapMutations('UserStore', ['setUserData']),
    async handleSubmit() {
      this.isLoading = true
      try {
        const params = this.data

        if(params.password) {
          if(params.password && !this.confirmPassword) throw new Error('Konfirmasi kata sandi Wajib diisi')
          if(params.password && !params.oldPassword) throw new Error('Kata sandi lama Wajib diisi')
          if(params.password.length < 6) throw new Error('Kata sandi minimal 6 karaketer')
          if(params.password !== this.confirmPassword) throw new Error('Konfirmasi kata sandi tidak sesuai')
        }

        params.mobile = Number(this.$helper.sanitizePhoneNumber(params.mobile))
        await updateUser(params)
        const newData = await UserProfile()
        if(newData) this.setUserData(newData.data)
        this.$helper.errorNotification(`Update success`, 'success')
      } catch (e) {
        const message = e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message
        this.$helper.errorNotification(message)
      }
      this.isLoading = false
    }
  },
  mounted() {
    this.data = {...this.userData}
  }
}
</script>
<style lang="scss" scoped></style>
