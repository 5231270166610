<template>
	<div class="shadow-lg mt-5 pt-2 pb-2">
		<div class="footer-container">
			<div class="row">
				<div class="col-sm-6 text-left">
					<h5 class="font-weight-bold mb-3">Tentang Kitorang</h5>
					<p>
						SIMTARU 2.0 PAPUA dikembangkan dengan menggunakan aplikasi
						Open Source yang merupakan bagian dari Sistem Manajemen Informasi berbasis Spasial (SMIS).
						Aplikasi ini merupakan pengembangan dari IMS dikembangkan dari kegiatan PLUP
						Program Compact Hibah MCC yang dilaksanakan oleh Kemeterian PPN/Bappenas. Sajian informasi spasial
						merupakan kombinasi teknologi kartografi modern dengan metode-metode partisipatif.
					</p>
				</div>

				<div class="col-sm-6 text-left">
					<h5 class="font-weight-bold mb-3">Kontak</h5>
					<p>
						<strong>Dinas Komunikasi Informatika Persandian dan Statistik Papua Barat</strong><br>
            Jln. Pur. Brigjen Abraham O. Ataruri, Belakang Kantor Satpol PP, Arfai Manokwari Papua Barat
            <br>
            papuabaratkominfo@gmail.com
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "FooterComponent"
}
</script>

<style scoped>
	.footer-container {
		width: 90%;
		margin: 30px auto;
	}
</style>
