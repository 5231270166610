<template>
  <div class="container">
    <div class="row py-5">
      <div class="col-12">
        <h1 class="mb-5">
          Privacy Policy
        </h1>
        <div class="content text-left">
          <p>
            This Privacy Policy governs how Laporkitong (The <strong>“Laporkitong”</strong> or
            <strong>“We”</strong> or <strong>“Us”</strong> or <strong>“Our”</strong>) collect and use your data when
            users operate the Laporkitong Application (The <strong>“Laporkitong app”</strong> or <strong>“Service”</strong>).
            By logging in and
            accessing the Laporkitong app, Users (<strong>“You”</strong>) have agreed and bound by the conditions
            listed in this
            policy.
          </p>
          <p>
            <strong>Data collection and use</strong>
          </p>
          <p>
            Laporkitong built the Laporkitong app as a free application.This Service is provided by SIMTARU
            Laporkan at no cost and is intended for use as is. Laporkitong provides tools for users to report any
            indication of field development activities that are not complying with spatial planning document. The report
            will be further processed by clearing unit of Kitorang Papua for further recommendation. Laporkitong
            work in compliance of monitoring and evaluation stage of Spatial Planning process as stated in the
            government regulation (Government Regulation (PP) no. 21/2021). Using the location of the device or reported
            coordinate, Laporkitong will evaluate the validity of the report and process accordingly. When you use
            our Service, you directly or indirectly provide your data to Us that We will protect and use methods that
            are set in this Policy. We may require you to provide us with certain personally identifiable information in
            the form of:
          </p>
          <ul>
            <li>
              Data that you enter when you are registering to the Laporkitong app, such as:
              <br>
              <ul>
                <li>Username and email address</li>
                <li>Full name</li>
                <li>Mobile phone number</li>
                <li>Photo</li>
                <li>Gender</li>
                <li>Birthdate</li>
                <li>Estimation location (GPS and network-based)</li>

              </ul>
            </li>
            <li>
              Messages, suggestions, impressions from You through chat or other interactions in the Laporkitong
              application
            </li>
            <li>
              Information about your reported object, such as:
              <br>
              <ul>
                <li>
                  Address
                </li>
                <li>
                  Estimation location (GPS)
                </li>
              </ul>
            </li>
            <li>
              All the activities that You do and data that You enter into the Laporkitong app
            </li>
          </ul>
          <p>
            We can collect data directly from You when You:
          </p>
          <ul>
            <li>
              Access and use Laporkitong app
            </li>
            <li>
              Send message through chat
            </li>
            <li>
              Contact Our support team
            </li>
            <li>
              Enter information in any form into Laporkitong app
            </li>
          </ul>
          <p>
            The data that You enter is Your own data that You think is right, and You are fully responsible for all data
            that You enter into the Laporkitong application.
          </p>
          <p>
            We will store your data as long as You use the Laporkitong app, and We will delete your data when you
            ask Us to delete it. As long as You use Our Laporkitong app, We can update the Laporkitong app
            from time to time. You need to know that the Laporkitong app updates can use your internet packet data.
          </p>
          <p>
            You need to know that We will not collect the following information from You:
          </p>
          <ul>
            <li>
              All personal identification numbers (PIN), passwords, or other information to access to your account
            </li>
            <li>
              Any content from SMS, e-mail, photos, videos, social media communication, and/or other files on your
              mobile phone
            </li>
          </ul>
          <p>
            <strong>
              Example of using your data
            </strong>
          </p>
          <p>
            We can use any data that You enter into Laporkitong app for, including but not limited to:
          </p>
          <ul>
            <li>
              Processing your data, such as:
              <ul>
                <li>
                  Checking the spatial planning compliance as per your report
                </li>
                <li>
                  Checking the location of your report to identify land use
                </li>
              </ul>
            </li>
            <li>
              Inform You on the development of the Laporkitong app, sales offer, and maintenance
            </li>
            <li>
              Knowing the information about your visit to the Laporkitong app, such as time of visit and what You
              did during the visit 
            </li>
            <li>
              Provide recap of all report in app display or web portal
            </li>
          </ul>
          <p>
            We may also collect and use your data to:
          </p>
          <ul>
            <li>
              Optimize and develop Laporkitong app, marketing, and others
            </li>
            <li>
              See the effectiveness of the Laporkitong app
            </li>
          </ul>

          <p>
            <strong>
              Distribution of data to third parties
            </strong>
          </p>
          <p>
            Laporkitong will not sell, rent or distribute your data without your permission unless otherwise
            specified in this Policy. By giving permission in any form to disclose your data, You allow Us to disclose
            and send the data to selected third parties, and You release Us for any loss or damage arising from third
            party failure to secure your data. We cannot guarantee the security of your data on third party systems and
            we have no control or responsibility for the privacy policies or content of third party services. You have
            the responsibility to check the privacy policies of each third party, so you can understand how they will
            handle your data.
          </p>
          <p>
            Laporkitong may disclose your personal information if necessary to potential buyers or government
            bodies. If possible and allowed, we will notify you if we disclose your data.
          </p>
          <p>
            <strong>
              Children’s Privacy
            </strong>
          </p>
          <p>
            Laporkitong app do not address anyone under the age of 13. We do not knowingly collect personally
            identifiable information from children under 13. In the case we discover that a child under 13 has provided
            Us with personal information, We immediately delete this from our servers. If You are a parent or guardian
            and You are aware that your child has provided Us with personal information, please contact Us so that We
            will be able to do the necessary actions.
          </p>
          <p>
            <strong>
              Changes to This Privacy Policy
            </strong>
          </p>
          <p>
            We may update Our Privacy Policy from time to time. Thus, You are advised to review this page periodically
            for any changes. We will notify You of any changes by posting the new Privacy Policy on this page. These
            changes are effective immediately after they are posted on this page.
          </p>
          <p>
            <strong>
              Contact Us
            </strong>
          </p>
          <p>
            If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at <a
              href="mailto:kitorang.papuabarat@gmail.com" style="color: blue">kitorang.papuabarat@gmail.com</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PrivacyPolicyScreen'
}
</script>
<style lang="scss" scoped></style>
