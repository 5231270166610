<template>
	<CustomSelectComponent @input="handleInput" :value="value" add-class="mb-3" :options="provinceOptions" placeholder="Propinsi" />
</template>

<script>
import {getProvince} from "../../service/LocationService";
import CustomSelectComponent from "./CustomSelectComponent";
import {mapState, mapMutations} from "vuex"

export default {
	name: "SelectProvinceComponent",
	components: {CustomSelectComponent},
  props: {
    value: {
      type: [Number, String],
      default: ''
    }
  },
	async mounted() {
		if (this.provinces.length) {
			this.provinceOptions = this.provinces
		} else {
			let res = await getProvince({})
			this.provinceOptions = res.data
			this.setProvinces(res.data)
		}
	},
	data() {
		return {
			provinceOptions: []
		}
	},
	computed: {
		...mapState('LocationStore', ['provinces'])
	},
	methods: {
		...mapMutations('LocationStore', ['setProvinces']),
    handleInput(e) {
      this.$emit('input', this.provinceOptions.find(item => {
        return String(item.id) === String(e)
      }))
    }
	},
}
</script>

<style scoped>

</style>
