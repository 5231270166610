<template>
	<div id="app" class="h-100">
		<transition>
			<component :is="component" ></component>
		</transition>
		<PopUpComponent />
		<ModalRatingComponent />
	</div>
</template>

<style lang="scss">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

#nav {
	padding: 30px;

	a {
		font-weight: bold;
		color: #2c3e50;

		&.router-link-exact-active {
			color: #42b983;
		}
	}
}
</style>

<script>
import PopUpComponent from "./components/notification/PopUpComponent";
import DefaultLayout from "./views/layout/DefaultLayout";
import MapLayout from "./views/layout/MapLayout";
import AuthLayout from "./views/layout/AuthLayout";
import ModalRatingComponent from "./views/components/ModalRatingComponent";

export default {
	components: {ModalRatingComponent, PopUpComponent, DefaultLayout, MapLayout, AuthLayout},
	computed: {
		component() {
			if(this.$route.meta.layout) return this.$route.meta.layout
			return 'DefaultLayout'
		}
	}
}
</script>
