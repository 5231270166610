import Vue from 'vue'
import VueRouter from 'vue-router'
import DashboardScreen from "../views/screen/DashboardScreen";
import ReportListScreen from "../views/screen/reports/ReportListScreen";
import ReportDetailScreen from "../views/screen/reports/ReportDetailScreen";
import AuthScreen from "../views/screen/AuthScreen";
import Store from "../store/index";
import {UserProfile, validateCrossToken} from "../service/AuthService";
import LogoutScreen from "../views/screen/auth/LogoutScreen";
import UserListScreen from "../views/screen/users/UserListScreen";
import EditProfileScreen from "../views/screen/profile/EditProfileScreen";
import UserAddScreen from "../views/screen/users/UserAddScreen";
import {findMyReports} from "../service/ReportService";
import PrivacyPolicyScreen from "../views/screen/page/PrivacyPolicyScreen";
import TermOfUseScreen from "../views/screen/page/TermOfUseScreen";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: DashboardScreen,
    meta: {
      isLogin: false,
      layout: 'MapLayout'
    }
  },
  {
    path: '/report-list',
    component: {
      render(c) {
        return c('router-view')
      }
    },
    children: [
      {
        path: 'list',
        component: ReportListScreen,
        name: 'ReportList',
        meta: {
          isLogin: false,
          layout: 'DefaultLayout'
        }
      },
      {
        path: '/report/:id',
        name: 'ReportDetail',
        component: ReportDetailScreen,
        meta: {
          isLogin: false,
          layout: 'DefaultLayout'
        }
      },
    ]
  },
  {
    path: '/users',
    component: {
      render(c) {
        return c('router-view')
      }
    },
    children: [
      {
        path: '/',
        name: 'UserList',
        component: UserListScreen,
        meta: {
          isLogin: true,
          isSuperAdmin: true,
          layout: 'DefaultLayout'
        }
      },
      {
        path: 'add',
        name: 'UserAdd',
        component: UserAddScreen,
        meta: {
          isLogin: true,
          isSuperAdmin: true,
          layout: 'DefaultLayout'
        }
      },
      {
        path: ':id',
        name: 'UserDetail',
        component: UserAddScreen,
        meta: {
          isLogin: true,
          isSuperAdmin: true,
          layout: 'DefaultLayout'
        }
      }
    ]
  },
  {
    path: '/edit-profile',
    name: 'EditProfile',
    component: EditProfileScreen,
    meta: {
      isLogin: false,
      layout: 'DefaultLayout'
    }
  },

  {
    path: '/login',
    name: 'Login',
    component: AuthScreen,
    meta: {
      isLogin: false,
      layout: 'AuthLayout'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: AuthScreen,
    meta: {
      isLogin: false,
      layout: 'AuthLayout'
    }
  },
  {
    path: '/register-social',
    name: 'RegisterSocial',
    component: AuthScreen,
    meta: {
      isLogin: false,
      layout: 'AuthLayout'
    }
  },
  {
    path: '/activate-email',
    name: 'ActivateEmail',
    component: AuthScreen,
    meta: {
      isLogin: false,
      layout: 'AuthLayout'
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: AuthScreen,
    meta: {
      isLogin: false,
      layout: 'AuthLayout'
    }
  },
  {
    path: '/reset-password-validation',
    name: 'ConfirmResetPassword',
    component: AuthScreen,
    meta: {
      isLogin: false,
      layout: 'AuthLayout'
    }
  },
  {
    path: '/logout',
    name: 'Logout',
    component: LogoutScreen,
    meta: {
      isLogin: false,
      layout: 'AuthLayout'
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicyScreen,
    meta: {
      isLogin: false,
      layout: 'DefaultLayout'
    }
  },
  {
    path: '/term-condition',
    name: 'TermCondition',
    component: TermOfUseScreen,
    meta: {
      isLogin: false,
      layout: 'DefaultLayout'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return {x: 0, y: 0}
  }
})

router.beforeEach(async (to, from, next) => {
  let accessToken = localStorage.getItem('accessToken')
  const {token} = to.query

  if (token && to.path !== '/activate-email' && to.path !== '/reset-password-validation') {
    try {
      const parameter = {
        token
      }
      const response = await validateCrossToken(parameter)
      if (response && response.data.token) {
        localStorage.setItem('accessToken', response.data.token)
        accessToken = response.data.token
        const res = await UserProfile()
        if (res && res.data) {
          Store.commit('UserStore/setUserData', res.data)
          next()
        }
      }
    } catch (e) {
      localStorage.clear()
      next({name: 'Logout'})
    }
  }else if (accessToken && !Store.state.UserStore.userData) {
    try {
      const res = await UserProfile()
      if (res && res.data) {
        Store.commit('UserStore/setUserData', res.data)
      }
    } catch (e) {
      if (e && e.response && e.response.status === 401) {
        localStorage.clear()
        Store.commit('UserStore/setUserData', '')
        next({
          name: 'Logout'
        })
      }
    }
  } else if (accessToken && !to.meta.isLogin && (to.path === '/login' || to.path === '/register' || to.path === '/register-social')) {
    next({name: 'Dashboard'})
  } else if (accessToken && to.meta.isSuperAdmin && !Store.getters["UserStore/isSuperAdmin"]) {
    next({name: 'Dashboard'})
  } else if (to.meta.isLogin && !accessToken) {
    localStorage.clear()
    Store.commit('UserStore/setUserData', null)
    next({name: 'Logout'})
  }

  if(accessToken) {
    const params = {
      filter: {}
    }
    params.filter['where[status]'] = 'selesai'
    params.filter['where[rating]'] = 0
    try {
      const unratedReport = await findMyReports(params)

      if (unratedReport.data && unratedReport.data.total) {
        Store.commit('ReportStore/setUnratedReportData', unratedReport.data.data[0])
        Store.commit('ReportStore/setIsShowRatingReportModal', true)
      }
    } catch (e) {
      console.log(e)
    }
  }

  next()
})

export default router
