<template>
  <div
      :style="{backgroundImage: `url(${avatarUrl})`}"
      class="avatar-container position-relative d-flex align-items-center justify-content-center">
    <LoadingComponent v-if="isLoading" />
    <div
        v-if="!avatarUrl"
        class="user-container-icon">
      <i class="fa fa-user-alt"></i>
    </div>
    <div @click="triggerUploadImages" class="edit-btn-container cursor-pointer">
      <i class="fa fa-edit"></i>
    </div>
    <input @change="uploadImage" type="file" class="d-none" ref="fileUpload">
  </div>
</template>
<script>
import {fileUpload} from "../../../../service/FileService";
import LoadingComponent from "../../../../components/general/LoadingComponent";

export default {
  name: 'UserAvatarComponent',
  components: {LoadingComponent},
  data() {
    return {
      isLoading: false
    }
  },
  props: {
    image: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    avatarUrl() {
      if(this.image && this.image.url) return this.image.url
      return ''
    }
  },
  methods: {
    async uploadImage() {
      this.isLoading = true

      let file = this.$refs.fileUpload.files[0]

      try {
        let res = await fileUpload({
          file
        })
        if (res) {
          this.$emit('uploaded', res.data)
        }
      } catch (e) {
        console.log('upload file error', e)
        this.$helper.errorNotification('upload file failed')
      }

      this.isLoading = false
    },
    triggerUploadImages() {
      let input = this.$refs["fileUpload"]
      input.click()
    },
  }
}
</script>
<style lang="scss" scoped>
.avatar-container {
  width: 150px;
  height: 150px;
  background-color: #FF6247;
  border-radius: 50% !important;
  margin: 0 auto;
  padding-bottom: 0;
  background-position: center;
  background-size: cover;
}

.edit-btn-container {
  background-color: #2957A4;
  position: absolute;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: white;
  align-items: center;
  justify-content: center;
  right: 5px;
  bottom: 15px;
  font-size: .8rem;
  display: flex;
}
.user-container-icon {
  color: #FFD657;
  font-size: 4rem;
}
</style>
