<template>
	<div>
		<div class="px-4 py-3 shadow">
			<h5 class="text-custom-primary d-flex justify-content-between align-items-center">
				<strong>Buat Pengaduan</strong>
				<a @click.prevent="closeCreatePengaduan" class="cursor-pointer">
					<span><i class="fa fa-long-arrow-alt-left"></i></span>
				</a>
			</h5>
		</div>

		<form @submit.prevent="previewNewReport">
			<div class="create-report-container">
				<UploadComponent
					:images="report.images"
					@uploaded="handleFileUpload($event)" />
				<div class="px-4 py-3">
					<div class="text-left mt-3">
						<h5 class="text-custom-primary"><strong>Lokasi</strong></h5>

						<div class="mt-4">
							<p class="mb-3"><strong>Lokasi Pelaporan</strong></p>

							<div class="d-flex justify-content-start align-items-center">
								<h4 class="mr-3">
									<i class="fa fa-map-marker-alt"></i>
								</h4>
								<p class="text-left" v-if="currentContext">
									{{ `${currentContext.geometry.location.lat} ${currentContext.geometry.location.lng}` }} <br>
									{{ currentContext.formatted_address }}
								</p>
								<p class="text-left" v-else>
									<em>Pilih Lokasi</em>
								</p>
								<input
									@input="handleInput($event, 'latitude')"
									:value="report.longitude"
									type="hidden" required>
								<input
									@input="handleInput($event, 'longitude')"
									:value="report.longitude" type="hidden" required>
								<input
									@input="handleInput($event, 'longitude')"
									:value="report.location" type="hidden" required>
							</div>
							<hr>
						</div>

						<div class="mt-4">
							<p class="mb-4"><strong>Informasi Tambahan</strong></p>
							<SelectDistanceComponent @input="handleInput($event, 'distance')" :value="report.distance.id" class="mb-4"/>
							<SelectDirectionComponent v-if="isLocationNotExactly" @input="handleInput($event, 'direction')" :value="report.direction.id" class="mb-4"/>
							<SelectCityComponent @input="handleInput($event, 'city')" :value="report.city.id" :province-id="report.province.id" class="mb-4" :class="{ 'opacity-5': !report.province.id }"/>
							<SelectDistrictComponent @input="handleInput($event, 'district')" :value="report.district.id" :city-id="report.city.id" class="mb-4" :class="{ 'opacity-5': !report.city.id }"/>
							<SelectVillageComponent @input="handleInput($event, 'villages')" :value="report.villages.id" :district-id="report.district.id" class="mb-4" :class="{ 'opacity-5': !report.district.id }"/>
						</div>
					</div>
				</div>

				<div class="px-4 py-3">
					<div class="text-left">
						<h5 class="text-custom-primary"><strong>Apa yang ingin dilaporkan ?</strong></h5>

						<div class="mt-4">
							<p class="mb-4"><strong>Informasi Tambahan</strong></p>
							<SelectReportCategoryComponent @input="handleInput($event, 'reportCategory')" :value="report.reportCategory.id" class="mb-4"/>
							<SelectReportTypeComponent @input="handleInput($event, 'reportType')" :value="report.reportType.id" :category-id="report.reportCategory.id" class="mb-4" :class="{ 'opacity-5': !report.reportCategory.id }"/>
							<CustomInputComponent @input="handleInput($event, 'description')" :value="report.description" class="mb-4" placeholder="Deskripsi"/>
						</div>
					</div>
				</div>

				<div class="px-4 py-3">
					<div class="text-left">
						<h5 class="text-custom-primary"><strong>Tentang Pelapor</strong></h5>

						<div class="mt-4">
							<p class="mb-4"><strong>Informasi Tambahan</strong></p>
							<SelectInstitutionComponent @input="handleInput($event, 'institusi')" :value="report.institusi.id"/>
							<CustomInputComponent @input="handleInput($event, 'institusiName')" :value="report.institusiName" class="mb-4" placeholder="Nama Institusi"/>
							<CustomInputComponent @input="handleInput($event, 'address')" :value="report.address" class="mb-4" placeholder="Alamat"/>
						</div>
					</div>
				</div>
			</div>

			<div class="px-4 pb-5 pt-3">
				<div class="row">
					<div class="col-sm-12">
						<button type="submit" class="btn btn-yellow rounded-pill font-weight-bold">
							<span><i class="fas fa-paper-plane mr-2"></i></span>
							Kirim Pengaduan
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import CustomInputComponent from "../../../components/input/CustomInputComponent";
import CustomSelectComponent from "../../../components/input/CustomSelectComponent";
import {mapState, mapMutations} from "vuex"
import SelectDistanceComponent from "../../../components/input/SelectDistanceComponent";
import SelectDirectionComponent from "../../../components/input/SelectDirectionComponent";
import SelectProvinceComponent from "../../../components/input/SelectProvinceComponent";
import SelectCityComponent from "../../../components/input/SelectCityComponent";
import SelectDistrictComponent from "../../../components/input/SelectDistrictComponent";
import SelectVillageComponent from "../../../components/input/SelectVillageComponent";
import SelectReportCategoryComponent from "../../../components/input/SelectReportCategoryComponent";
import SelectReportTypeComponent from "../../../components/input/SelectReportTypeComponent";
import SelectInstitutionComponent from "../../../components/input/SelectInstitutionComponent";
import UploadComponent from "../../../components/general/UploadComponent";

export default {
	name: "SidebarCreateReportComponent",
	components: {
		UploadComponent,
		SelectInstitutionComponent,
		SelectReportTypeComponent,
		SelectReportCategoryComponent,
		SelectVillageComponent,
		SelectDistrictComponent,
		SelectCityComponent,
		SelectProvinceComponent,
		SelectDirectionComponent, SelectDistanceComponent, CustomSelectComponent, CustomInputComponent
	},
	computed: {
		...mapState('MapsStore', ['currentContext']),
		...mapState('UserStore', ['userData']),
		...mapState('ReportStore', ['report']),
		isLocationNotExactly() {
			return this.report.distance.id !== 1
		}
	},
	methods: {
		...mapMutations('ReportStore', ['setMarkerActive', 'setReport', 'resetReportData']),
		...mapMutations('MapsStore', ['resetDefaultCoordinate','setAllowCreateMarker']),
		...mapMutations('SidebarStore', ['togglePreviewReport','setIsShowSidebar', 'setIsCreateReport','setIsShowReportList','setIsPreviewReport']),
		previewNewReport() {
			this.setIsPreviewReport(true)
			this.setIsCreateReport(false)
		},
		handleFileUpload(images) {
			this.handleInput(images, 'images')
		},
		closeCreatePengaduan() {
			this.setIsCreateReport(false)
			this.setIsShowReportList(true)
		},
		handleInput(e, key) {
			const reportData = {...this.report}
			reportData[key] = e
			this.setReport(reportData)
		}
	},
	watch: {
		report: {
			handler() {
				this.renderKey += 1
			},
			deep: true
		},
		currentContext() {
			this.report.latitude = this.currentContext.geometry.location.lat
			this.report.longitude = this.currentContext.geometry.location.lng
			this.report.location = this.currentContext.formatted_address
		}
	},
	mounted() {
		this.setMarkerActive(false)
		this.resetDefaultCoordinate()
		this.setAllowCreateMarker(true)
		if(this.userData) {
			const {institusiName, address, institusi} = this.userData
			this.handleInput(institusiName, 'institusiName')
			this.handleInput(address, 'address')
			if(institusi) this.handleInput(institusi, 'institusi')
		}
	},
	beforeDestroy() {
		this.setMarkerActive(true)
	}
}
</script>

<style scoped lang="scss">
p, h4, h5 {
	margin-bottom: 0;
}

.create-report-container {
	overflow-y: auto;
}
</style>
