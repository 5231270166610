<template>
	<date-range-picker
		ref="picker"
		v-model="dateRange"
		:date-format="dateFormat"
		@update="handleInput"
		:autoApply="true"
		:maxDate="disableFutureDate ? new Date() : ''"
		:single-date-picker="false"
		:disabled="disabled"
		:class="{ 'is-disabled': disabled }"
		opens="right"
	>
		<div slot="input"
		     class="w-100 d-flex align-items-center justify-content-between"
		     slot-scope="picker">
			<p class="m-0">{{ renderTextDateInput() }}</p>
			<i class="fa fa-calendar"></i>
		</div>
	</date-range-picker>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
	name: 'DaterangeComponent',
	props: {
		placeholder: {
			type: String,
			default: 'Periode Tanggal'
		},
		value: {
			type: Object,
			required: false,
			default: () => {
				return {
					startDate: null,
					endDate: null
				}
			}
		},
		disableFutureDate: {
			type: Boolean,
			default: false
		},
		singleDatePicker: {
			type: Boolean,
			default: false
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	components: {
		DateRangePicker
	},
	data() {
		return {
			dateRange: this.value,
		}
	},
	watch: {
		value(val) {
			this.dateRange = val
		}
	},
	methods: {
		handleInput(e) {
			const date = {
				endDate: e.endDate ? this.$helper.formatDatetime(e.endDate) : '',
				startDate: e.startDate ? this.$helper.formatDatetime(e.startDate) : ''
			}

			this.$emit('input', date)
		},
		dateFormat(classes, date) {
			return classes
		},
		renderDate(val) {
			return val ? this.$helper.formatDate(val) : "";
		},
		renderTextDateInput() {
			const {startDate, endDate} = this.dateRange

			if (this.singleDatePicker && startDate) {
				return this.renderDate(startDate)
			}

			if(startDate && endDate) {
				return `${this.renderDate(startDate)} - ${this.renderDate(endDate)}`
			}

			return this.placeholder
		}
	},
	mounted() {
		this.dateRange = this.value
	}
}
</script>
<style lang="scss">
.vue-daterange-picker {
	width: 100%;

	.form-control {
		border-radius: 50px;

		i {
			margin-right: 5px
		}
	}

	.daterangepicker {
		&.dropdown-menu {
			background-color: white !important;
			border: 1px solid #ccc;
		}

		.ranges {
			border: none;
			margin-left: 10px;
		}
	}

	.calendars {
		margin-left: -10px;
		margin-right: -10px;
	}

	.reportrange-text {
		border: 1px solid #ddd;
	}
}

.is-disabled {

	.reportrange-text {
		background-color: #fafafa !important;
		opacity: 1 !important;
	}
}

</style>
