<template>
	<div class="">
		<a v-if="currentPage > 1" class="p-1 mx-3" @click.prevent="changePage(currentPage - 1)"><i class="fa fa-chevron-left"></i></a>
		<a class="p-1 mx-3" v-for="(item, index) in pages" @click.prevent="changePage(item)" :class="{ 'active': item === currentPage }" v-html="item"></a>
		<a v-if="currentPage < countPage" class="p-1 mx-3" @click.prevent="changePage(currentPage + 1)"><i class="fa fa-chevron-right"></i></a>
	</div>
</template>

<script>
const MAX_VISIBLE_NUMBER = 5

export default {
	name: "PaginationComponent",
	props: {
		currentPage: {
			type: Number,
			default: 1
		},
		totalData: {
			type: Number,
		},
		limit: {
			type: Number
		}
	},
	computed: {
		countPage() {
			return Math.round(this.totalData / this.limit)
		},
		startPage() {
			if (Number(this.currentPage) === 1) {
				return 1
			}

			return this.currentPage - 1
		},
		totalPage() {
			return Math.ceil(this.totalData / this.limit)
		},
		pages() {
			const range = []
			for (
				let i = this.startPage;
				i <= Math.min(this.startPage + MAX_VISIBLE_NUMBER - 1, this.totalPage);
				i += 1
			) {
				range.push(i)
			}
			return range
		}
	},
	methods: {
		changePage(page) {
			if (this.currentPage !== page) {
				this.$emit('change-page', page)
			}
		}
	}
}
</script>

<style scoped lang="scss">
	a {
		cursor: pointer;
	}

	.active {
		position: relative;
		color: white;

		&:hover {
			color: white;
		}

		&:before {
			display: inline-block;
			position: absolute;
			top: 0;
			right: -50%;
			left: -50%;
			bottom: 0;
			margin: auto;
			z-index: -1;
			content: ' ';
			width: 30px;
			height: 30px;
			background: #717171;
			border-radius: 50px;
		}
	}
</style>
