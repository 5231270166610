<template>
  <div
      ref="loginGoogle"
      class="btn btn-sm btn-google rounded-pill btn-block text-white">
    <i class="fab fa-google mr-1"></i> Google
  </div>
</template>
<script>
import {mapMutations, mapState} from "vuex";
import {loginSocial, UserProfile} from "../service/AuthService";

export default {
  name: 'GoogleLoginButtonComponent',
  methods: {
    ...mapMutations('UserStore', ['setUserData']),
    doLoginGoogle() {
      let auth
      gapi.load('auth2', () => {
        auth = gapi.auth2.init({
          client_id: '323221183285-lgfa2v6143kurbc4mr5r4gls8dno01i9.apps.googleusercontent.com',
          cookiepolicy: 'single_host_origin',
        });

        auth.attachClickHandler(this.$refs.loginGoogle, {},
            async (googleUser) => {
              var profile = googleUser.getBasicProfile();
              var id = profile.getId();
              var email = profile.getEmail();
              const params = {
                googleId: id,
                email: email,
                type: 'sosmed'
              }
              this.doLogin(params)
            })
      });
    },
    async doLogin(params) {
      try {
        const res = await loginSocial(params)
        if (res && res.data) {
          localStorage.setItem('accessToken', res.data.token)
          await this.doGetUserData()

          let name = ''
          if (this.typeSimtaruProfile) name = 'EditProfile'
          else name = 'Dashboard'

          await this.$router.push({ name })
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          await this.$router.push({
            name: 'RegisterSocial',
            params: {
              data: params
            }
          })
        }
      }
    },
    async doGetUserData() {
      try {
        const res = await UserProfile()
        if (res && res.data) {
          this.setUserData(res.data)
        }
      } catch (e) {

        await this.$store.dispatch('NotificationStore/openNotification', {
          message: e.response.data.message,
          type: 'error'
        })
      }
    }
  },
  mounted() {
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://apis.google.com/js/api.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'google-api'));
    setTimeout(() => {
      this.doLoginGoogle()
    }, 1000)
  },
  computed: {
    ...mapState('UserStore', ['typeSimtaruProfile'])
  }
}
</script>
<style lang="scss" scoped>
.btn-google {
  background-color: #DB4437;
}
</style>
