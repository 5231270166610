<template>
	<CustomSelectComponent @input="handleInput" :value="value" add-class="mb-3" :options="districtOptions" placeholder="Distrik" />
</template>

<script>
import {getKecamatan} from "../../service/LocationService";
import CustomSelectComponent from "./CustomSelectComponent";
import {mapState, mapMutations} from "vuex"

export default {
	name: "SelectDistrictComponent",
	components: {CustomSelectComponent},
	props: {
		cityId: {
			type: Number,
			required: false,
			default: ''
		},
    value: {
      type: [Number, String],
      default: ''
    }
	},
	async mounted() {
		this.getDistrictOptions()
	},
	data() {
		return {
			selected: '',
			districtOptions: []
		}
	},
	methods: {
		...mapMutations('LocationStore', ['setDistricts']),
		async getDistrictOptions() {
			if (this.cityId) {
				let res = await getKecamatan({}, this.cityId)
				this.districtOptions = res.data
			}
		},
    handleInput(e) {
      this.$emit('input', this.districtOptions.find(item => {
        return String(item.id) === String(e)
      }))
    }
	},
	watch: {
		cityId() {
			this.getDistrictOptions()
		}
	},
	computed: {
		...mapState('LocationStore', ['districts'])
	}
}
</script>

<style scoped>

</style>
