<template>
	<CustomSelectComponent @input="handleInput" :value="value" add-class="mb-3" :options="cityOptions" placeholder="Kabupaten" />
</template>

<script>
import {getKabupaten} from "../../service/LocationService";
import CustomSelectComponent from "./CustomSelectComponent";
import {mapState, mapMutations} from "vuex"

export default {
	name: "SelectCityComponent",
	components: {CustomSelectComponent},
	props: {
		provinceId: {
			type: Number,
			required: false,
			default: ''
		},
    value: {
      type: [Number, String],
      default: ''
    }
	},
	async mounted() {
		this.getCityOptions()
	},
	data() {
		return {
			cityOptions: []
		}
	},
	methods: {
		...mapMutations('LocationStore', ['setCities']),
		async getCityOptions() {
			if (this.provinceId) {
				let res = await getKabupaten({}, this.provinceId)
				this.cityOptions = res.data
			}
		},
    handleInput(e) {
      this.$emit('input', this.cityOptions.find(item => {
        return String(item.id) === String(e)
      }))
    }
	},
	watch: {
		provinceId() {
			this.getCityOptions()
		}
	},
	computed: {
		...mapState('LocationStore', ['cities'])
	}
}
</script>

<style scoped>

</style>
