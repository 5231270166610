import GlobalMercator from "./GlobalMercator";

let gm = new GlobalMercator;

const getWmsBbox = (lat, lon, zoom) => {
    let tile = gm.LatLonToTile(lat, lon, zoom)
    let bboxmeters = gm.TileBounds(tile.ty, tile.tx, zoom)
    let bboxlatlonmin = gm.MetersToLatLon(bboxmeters.miny, bboxmeters.minx)
    let bboxlatlonmax = gm.MetersToLatLon(bboxmeters.maxy, bboxmeters.maxx)

    let bbox = [
        bboxlatlonmin.lon,
        bboxlatlonmin.lat,
        bboxlatlonmax.lon,
        bboxlatlonmax.lat
    ]

    return(bbox)
}

const getWmsPixelXy = (lat, lon, zoom, pixelsize) => {
    let xymeters = gm.LatLonToMeters(lat, lon, zoom)
    let xypixels = gm.MetersToPixels(xymeters.mx, xymeters.my, zoom)

    let pixelxy = {
        px: Math.round(xypixels.px % pixelsize),
        py: Math.round(xypixels.py % pixelsize)
    }
    return(pixelxy)
}

export const getWmsInfo = (lat, lon, zoom, pixelsize, layer) => {
    const bbox = getWmsBbox(lat, lon, zoom)
    const xy = getWmsPixelXy(lat, lon, zoom, pixelsize)

    const geoServerUrl = 'https://kitorang.papuabaratprov.go.id/geoserver/geonode/wms?'
    let url = geoServerUrl +
        'service=WMS' +
        '&version=1.1.0' +
        '&request=GetFeatureInfo' +
        '&layers=' + encodeURIComponent(layer) +
        '&srs=EPSG:4326' +
        '&bbox=' + bbox +
        '&width=' + pixelsize +
        '&height=' + pixelsize +
        '&query_layers=' + encodeURIComponent(layer) +
        '&x=' + xy.px +
        '&y=' + xy.py +
        '&info_format=application/json'

    return(url)
}
