<template>
  <div
      @click="doLoginFb()"
      class="btn btn-sm btn-facebook rounded-pill btn-block text-white">
    <i class="fab fa-facebook-f mr-1"></i> Facebook
  </div>
</template>
<script>
import {loginSocial, UserProfile} from "../service/AuthService";
import {mapMutations, mapState} from "vuex";

export default {
  name: 'FacebookLoginButtonComponent',
  mounted() {
    window.fbAsyncInit = function () {
      FB.init({
        appId: '1180000992754102',
        cookie: true,
        xfbml: true,
        version: 'v12.0'
      });
    };

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  },
  methods: {
    ...mapMutations('UserStore', ['setUserData']),
    doLoginFb() {
      FB.login((res) => {
        if (res && res.status === 'connected') {
          FB.api('/me?fields=id,name,email', async (response) => {
            const params = {
              facebookId: response.id,
              email: response.email,
              type: 'sosmed'
            }
            this.doLogin(params)
          });
        }
      })
    },
    async doLogin(params) {
      try {
        const res = await loginSocial(params)
        if (res && res.data) {
          localStorage.setItem('accessToken', res.data.token)
          await this.doGetUserData()

          let name = ''
          if (this.typeSimtaruProfile) name = 'EditProfile'
          else name = 'Dashboard'

          await this.$router.push({ name })
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$router.push({
            name: 'RegisterSocial',
            params: {
              data: params
            }
          })
        }
      }
    },
    async doGetUserData() {
      try {
        const res = await UserProfile()
        if (res && res.data) {
          this.setUserData(res.data)
        }
      } catch (e) {
        await this.$store.dispatch('NotificationStore/openNotification', {
          message: e.response.data.message,
          type: 'error'
        })
      }
    }
  },
  computed: {
    ...mapState('UserStore', ['typeSimtaruProfile'])
  }
}
</script>
<style lang="scss" scoped>
.btn-facebook {
  background-color: #3578e5;
}
</style>
