<template>
	<div class="row w-100 mb-3">
		<div class="col-sm-3">
			{{ $helper.formatDate(data.createAt, true)}}
		</div>
		<div class="col-sm-9">
			<strong>{{ data.status.name}}</strong>
			<br>
			<p v-if="data.status.id === 9">Rekomendasi: <br></p>
			{{ data.notes }}
		</div>
	</div>
</template>
<script>
export default {
	name: 'ReportStatusItemComponent',
	props: {
		data: {
			type: Object,
			required: true
		}
	}
}
</script>
<style lang="scss" scoped></style>
