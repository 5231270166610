const LocationStore = {
  namespaced: true,
  state: {
    distances: [],
    directions: [],
    provinces: [],
    cities: [],
    districts: [],
    villages: [],
    reportCategory: [],
    reportType: [],
    institutions: [],
    status: [],
    roles: []
  },
  mutations: {
    setDistances(state, value) {
      state.distances = value
    },
    setDirections(state, value) {
      state.directions = value
    },
    setProvinces(state, value) {
      state.provinces = value
    },
    setCities(state, value) {
      state.cities = value
    },
    setDistricts(state, value) {
      state.districts = value
    },
    setVillages(state, value) {
      state.villages = value
    },
    setReportCategory(state, value) {
      state.reportCategory = value
    },
    setReportType(state, value) {
      state.reportType = value
    },
    setInstitutions(state, value) {
      state.institutions = value
    },
    setStatus(state, value) {
      state.status = value
    },
    setRoles(state, value) {
      state.roles = value
    }
  },
  actions: {}
}

export default LocationStore
