const NotificationStore = {
    namespaced: true,
    state: {
        message: '',
        isOpen: false,
        typeIcon: ''
    },
    mutations: {
        openNotification(state, value) {
            state.isOpen = true
            state.message = value.message
            state.typeIcon = value.type
        },
        closeNotification(state, value) {
            state.isOpen = false
            state.message = ''
        },
        setWithCancelButton(state, value) {
            state.withCancelButton = value
        }
    },
    actions: {
        openNotification({commit}, value) {
            commit('openNotification', value)
        },
        closeNotification({commit}, value) {
            commit('closeNotification', value)
        }
    },
}

export default NotificationStore
