var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"report-info-panel",class:[_vm.isShowSidebar ? 'active' : '']},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-auto d-flex justify-content-between align-items-center border-right border-white"},[_c('div',{staticClass:"mx-1"},[_c('h2',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.statisticReport.laporan))])]),_vm._m(0)]),_c('div',{staticClass:"col-auto d-flex justify-content-between align-items-center"},[_vm._m(1),_c('div',{staticClass:"text-left mx-1"},[_c('h2',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.statisticReport.laporanBaru))])]),_vm._m(2)]),_c('div',{staticClass:"col-auto d-flex justify-content-between align-items-center"},[_vm._m(3),_c('div',{staticClass:"text-left mx-1"},[_c('h2',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.statisticReport.laporanVerifikasi))])]),_vm._m(4)]),_c('div',{staticClass:"col-auto d-flex justify-content-between align-items-center"},[_vm._m(5),_c('div',{staticClass:"text-left mx-1"},[_c('h2',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.statisticReport.laporanPenanganan))])]),_vm._m(6)]),_c('div',{staticClass:"col-auto d-flex justify-content-between align-items-center"},[_vm._m(7),_c('div',{staticClass:"text-left mx-1"},[_c('h2',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.statisticReport.laporanSelesai))])]),_vm._m(8)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-left mx-1"},[_vm._v(" Total "),_c('br'),_vm._v(" Aduan ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-1"},[_c('img',{staticClass:"img-fluid w-75",attrs:{"src":require("../../assets/images/map-marker-yellow.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-left mx-1"},[_vm._v(" Aduan "),_c('br'),_vm._v(" Baru ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-1"},[_c('img',{staticClass:"img-fluid w-75",attrs:{"src":require("../../assets/images/map-marker-red.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-left mx-1"},[_vm._v(" Aduan "),_c('br'),_vm._v(" Terverifikasi ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-1"},[_c('img',{staticClass:"img-fluid w-75",attrs:{"src":require("../../assets/images/map-marker-blue.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-left mx-1"},[_vm._v(" Aduan dalam "),_c('br'),_vm._v(" Penanganan ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-1"},[_c('img',{staticClass:"img-fluid w-75",attrs:{"src":require("../../assets/images/map-marker-green.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-left mx-1"},[_vm._v(" Aduan "),_c('br'),_vm._v(" Selesai ")])
}]

export { render, staticRenderFns }