<template>
  <router-view></router-view>
</template>
<script>
import UserHeaderComponent from "../components/UserHeaderComponent";
import FooterComponent from "../../components/general/FooterComponent";

export default {
  name: 'AuthLayout',
  components: {FooterComponent, UserHeaderComponent}
}
</script>
<style lang="scss" scoped></style>
