<template>
	<ModalComponent :is-show="isShowRatingReportModal"
	                :is-center="true"
	                :is-header="false">
		<template v-slot:body>
			<div v-if="reportUnratedData" class="row">
				<div class="col-sm-12">
					<div class="w-100 d-flex justify-content-between align-items-center mb-2">
						<div class="text-left">
							<small>{{ $helper.formatDate(reportUnratedData.updateAt) }}</small>
							<p class="m-0">{{ reportUnratedData.reportCategory ? reportUnratedData.reportCategory.name : '-' }}</p>
							<p><strong>{{ reportUnratedData.reportType ? reportUnratedData.reportType.name : '-' }}</strong></p>
						</div>

						<div class="d-flex">
							<div v-if="reportUnratedData.rating" class="d-flex">
								<div :class="getReportRating.textClass">
									<h3><i class="mr-3" :class="getReportRating.icon"></i></h3>
								</div>
							</div>
							<BadgeStatusComponent :data="reportUnratedData.status"/>
						</div>
					</div>
					<hr>
					<div class="text-left">
						<div class="row m-0 mt-3">
							<div class="col-auto pl-0 marker-container">
								<i class="fa fa-map-marker-alt h4"></i>
							</div>
							<div class="col-11">
								<p class="m-0 mb-3">
									{{ `${reportUnratedData.distance.name} arah ${reportUnratedData.direction ? reportUnratedData.direction.name : ''} dari` }} <br>
									{{ reportUnratedData.latitude }} , {{ reportUnratedData.longitude }} <br/>
									{{ reportUnratedData.location }}
								</p>

								<p>
									<strong>{{ reportUnratedData.status.name }}</strong> <br>
									Rekomendasi : {{ reportUnratedData.notes }}
								</p>
							</div>
						</div>
					</div>
					<hr>
					<div class="d-flex flex-column justify-content-between align-items-center">
						<small class="m-0 mb-4">Berikan Tanggapanmu</small>
						<ReportRatingComponent :id="reportUnratedData.id"
						                       :show-label="false"
						                       :show-notification="false"
						                       @update-success="reloadPage"/>
					</div>
				</div>
			</div>
		</template>
	</ModalComponent>
</template>

<script>
import ModalComponent from "../../components/general/ModalComponent";
import {mapState} from "vuex"
import BadgeStatusComponent from "../../components/BadgeStatusComponent";
import ReportStatusItemComponent from "../screen/reports/components/ReportStatusItemComponent";
import ReportRatingComponent from "../screen/reports/components/ReportRatingComponent";

export default {
	name: "ModalRatingComponent",
	components: {ReportRatingComponent, ReportStatusItemComponent, BadgeStatusComponent, ModalComponent},
	computed: {
		...mapState('ReportStore', ['isShowRatingReportModal', 'reportUnratedData']),
		getReportRating() {
			switch (this.reportUnratedData.rating) {
				case 1: return { textClass: 'text-success', icon: 'fas fa-laugh' };
					break;
				case 2: return { textClass: 'text-secondary', icon: 'fas fa-meh' };
					break;
				case 3: return { textClass: 'text-danger', icon: 'fas fa-frown' };
					break;
				default: return { textClass: '', icon: '' };
					break
			}
		},
	},
	methods: {
		reloadPage() {
			window.location.reload()
		}
	}
}
</script>

<style scoped>

</style>
