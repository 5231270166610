<template>
	<transition name="slide">
		<slot></slot>
	</transition>
</template>

<script>
export default {
	name: "SlideAnimationComponent"
}
</script>

<style scoped lang="scss">

</style>
