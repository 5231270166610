<template>
	<CustomSelectComponent @input="handleInput" :value="value" :options="villageOptions" placeholder="Kampung" />
</template>

<script>
import {getDesa} from "../../service/LocationService";
import CustomSelectComponent from "./CustomSelectComponent";
import {mapState, mapMutations} from "vuex"

export default {
	name: "SelectVillageComponent",
	components: {CustomSelectComponent},
	props: {
		districtId: {
			type: Number,
			required: false,
			default: ''
		},
      value: {
        type: [Number, String],
        default: ''
      }
	},
	async mounted() {
		await this.getVillageOptions()
	},
	data() {
		return {
			villageOptions: []
		}
	},
	methods: {
		...mapMutations('LocationStore', ['setVillages']),
		async getVillageOptions() {
			if (this.districtId) {
				let res = await getDesa({}, this.districtId)
				this.villageOptions = res.data
			}
		},
    handleInput(e) {
      this.$emit('input', this.villageOptions.find(item => {
        return String(item.id) === String(e)
      }))
    }
	},
	watch: {
		districtId() {
			this.getVillageOptions()
		}
	},
	computed: {
		...mapState('LocationStore', ['villages'])
	}
}
</script>

<style scoped>

</style>
