<template>
  <div class="info-window-container">
    <div class="row">
      <div class="col-sm-12 mb-3">
        <div class="d-flex justify-content-between">
          <div class="text-left">
            <p style="line-height: 1.3rem">
              {{ $helper.formatDate(data.createAt) }} <br>
              {{ data.reportType ? data.reportType.name : '-' }}
            </p>
            <p><strong>{{ data.reportCategory ? data.reportCategory.name : '-' }}</strong></p>
          </div>

          <BadgeStatusComponent :data="data.status"/>
        </div>
      </div>

      <div class="col-sm-12 text-left mb-3">
        <p>{{ `${data.latitude}, ${data.longitude}` }}</p>
        <p>{{ data.location }}</p>
      </div>

      <div
          v-if="dataLayer.objName"
          class="col-12 mb-3">
        <div class="text-left">
          <p class="font-weight-bold">{{ dataLayer.objName }} - {{ dataLayer.remarks }}</p>
        </div>
      </div>

      <div class="col-sm-12 text-right">
        <router-link :to="{ name: 'ReportDetail', params: { id: data.id } }"
                     class="btn btn-custom-primary btn-sm rounded-pill">
          Lihat Detail Pengaduan
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex'
import BadgeStatusComponent from "../../../components/BadgeStatusComponent";
import {getWmsInfo} from "../../../util/WmsHelper";
import axios from "axios";

export default {
  name: "InfoWindowDetailComponent",
  components: {BadgeStatusComponent},
  data() {
    return {
      dataLayer: {
        objName: '',
        remarks: ''
      }
    }
  },
  computed: {
    ...mapState('ReportStore', {
      data: 'selectedReport'
    }),
    ...mapState('MapsStore', ['activeLayer']),
  },
  methods: {
    ...mapMutations('ReportStore', ['setSelectedReport']),
    async getGlobalMercatorUrl() {
      if (this.activeLayer) {
        const url = getWmsInfo(
            this.data.latitude,
            this.data.longitude,
            10,
            256,
            decodeURIComponent(this.activeLayer.value)
        )

        try {
          const res = await axios.get(url)
          if (res.data) {
            const {features} = res.data
            if (features && features.length) {
              this.dataLayer = {
                objName: features[0].properties.NAMOBJ,
                remarks: features[0].properties.REMARKS,
              }
            }
          }
        } catch (e) {
          console.log('something went wrong')
        }
      }

    }
  },
  mounted() {
    this.getGlobalMercatorUrl()
  },
  beforeDestroy() {
    this.setSelectedReport(null)
  },
  watch: {
    'activeLayer.value'() {
      this.getGlobalMercatorUrl()
    }
  }
}
</script>

<style scoped lang="scss">
p {
  margin-bottom: 8px;
}

.info-window-container {
  width: 320px;
  padding: 8px 12px;
  position: relative;
  z-index: 10;
}
</style>
