<template>
  <CustomSelectComponent
      @input="handleInput"
      :value="value"
      add-class="mb-3"
      :options="institutionOptions" placeholder="Institusi"/>
</template>

<script>
import {getInstitusi} from "../../service/LocationService";
import CustomSelectComponent from "./CustomSelectComponent";
import {mapState, mapMutations} from "vuex"

export default {
  name: "SelectInstitutionComponent",
  components: {CustomSelectComponent},
  props: {
    value: {
      type: [Number, String],
      default: ''
    }
  },
  async mounted() {
    if (this.institutions.length) {
      this.institutionOptions = this.institutions
    } else {
      let res = await getInstitusi({})
      this.institutionOptions = res.data
      this.setInstitutions(res.data)
    }
  },
  data() {
    return {
      institutionOptions: []
    }
  },
  computed: {
    ...mapState('LocationStore', ['institutions']),
  },
  methods: {
    ...mapMutations('LocationStore', ['setInstitutions']),
    handleInput(e) {
      this.$emit('input', this.institutionOptions.find(item => {
        return String(item.id) === String(e)
      }))
    }
  },
}
</script>
