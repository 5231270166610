<template>
	<div class="custom-form-container" :class="addClass">
    <label v-if="value && !noLabel">{{ placeholder }}</label>
    <select class="custom-form-select"
		        @change="$emit('input', $event.target.value)"
		        :disabled="isDisabled"
		        :required="isRequired">
			<option value=""> {{ placeholder }} </option>
			<option v-for="(item, index) in options"
              :selected="String(value) === String(item.id)"
              :value="item.id" :key="index">{{ item.name }}</option>
		</select>
	</div>
</template>

<script>
export default {
	name: "CustomSelectComponent",
	props: {
		type: {
			type: String,
			required: false,
			default: 'text'
		},
		id: {
			type: String,
			required: false,
			default: ''
		},
		iconUnicode: {
			type: String,
			required: false
		},
		options : {
			type: Array,
			required: true,
			default: () => {
				return []
			}
		},
		addClass: {
			type: String,
			required: false,
			default: ''
		},
		placeholder: {
			type: String,
			required: false,
			default: '- Select -'
		},
		isDisabled: {
			type: Boolean,
			required: false,
			default: false
		},
		isRequired: {
			type: Boolean,
			required: false,
			default: true
		},
		value: {
			type: [Number, String],
			default: ''
		},
    noLabel: {
      type: Boolean,
      default: false
    }
	}
}
</script>

<style scoped lang="scss">
.custom-form-container {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	&:before {
		font-family: "Font Awesome 5 Free";
		content: "\f0d7";
		font-weight: 900;
		position: absolute;
		height: 50px;
		z-index: 1;
		right: 0;
		bottom: -14px;
	}
}

label {
	font-size: 0.8rem;
	transition: 0.3s ease all;
	-moz-transition: 0.3s ease all;
	-webkit-transition: 0.3s ease all;
  margin-bottom: 0;
}

.custom-form-select:focus ~ label,
.custom-form-select:valid ~ label {
	top: -15px;
}

.custom-form-select {
	-webkit-appearance: none;
	width: 100%;
	border: 0;
	padding: 10px 0;
	border-bottom: 1px solid #A4A4A4;

	&:focus {
		outline: none;
	}
}


</style>
