<template>
  <div class="position-relative" :style="{ height: files.length ? '100%' : '250px' }">
    <div
        :class="images.length ? 'upload-component-container-no-bg' : (isNoBackground ? 'upload-component-container-bg-gray' : 'upload-component-container')">
      <div v-if="!isLoading && !files.length"
           class="upload-component-btn w-100 h-100 d-flex justify-content-center align-items-center">
        <button
            @click.preve.prevent="triggerUploadImages" class="btn bg-white rounded-pill text-custom-primary">
          <span><i class="fa fa-camera mr-3"></i></span>
          <strong>{{ placeholder }}</strong>
        </button>
        <p v-if="files.length">Uploaded file: {{ this.files.length }}</p>
      </div>

      <ImageCarouselComponent
          v-else-if="files.length"
          :images="files"
          @remove="removeFile($event)"
          :delete-btn="!isNoButton"
      />

      <div v-else class="loading-fit">
        <LoadingComponent/>
      </div>
    </div>
    <div class="button-add-wrapper">
      <button v-if="files.length" @click.prevent="triggerUploadImages"
              class="btn btn-custom-primary border border-white rounded my-3">
        <span><i class="fa fa-camera mr-3"></i></span>
        <strong>Tambah Foto</strong>
      </button>

      <input @change="uploadImage" type="file" class="d-none" ref="fileUpload">
    </div>
  </div>
</template>

<script>
import {fileUpload} from "../../service/FileService";
import LoadingComponent from "./LoadingComponent";
import ImageCarouselComponent from "./ImageCarouselComponent";
import {mapActions} from "vuex";

export default {
  name: "UploadComponent",
  components: {ImageCarouselComponent, LoadingComponent},
  props: {
    images: {
      type: Array,
      default: () => {
        return []
      }
    },
    placeholder: {
      type: String,
      default: 'Foto Situasi'
    },
    isNoBackground: {
      type: Boolean,
      default: false
    },
    isNoButton: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      files: this.images,
      allowedTypes: ['image/jpg', 'image/jpeg', 'image/png'],
      isLoading: false
    }
  },
  watch: {
    images(val) {
      this.files = val
    }
  },
  methods: {
    ...mapActions('NotificationStore', ['openNotification']),
    removeFile(event) {
      this.files = this.files.filter((v) => {
        return v.id !== event.id
      })
      this.$emit('uploaded', this.files)
    },
    triggerUploadImages() {
      let input = this.$refs["fileUpload"]
      input.click()
    },
    async uploadImage() {
      this.isLoading = true

      let file = this.$refs.fileUpload.files[0]

      try {
        let res = await fileUpload({
          file
        })
        if (res) {
          this.files.push(res.data)
          this.$emit('uploaded', this.files)
        }
      } catch (e) {
        await this.openNotification({
          message: 'Telah terjadi kesalahan / Ukuran file terlalu besar',
          type: 'error'
        })
        console.log('upload file error', e)
      }

      this.$refs.fileUpload.value = ''

      this.isLoading = false
    }
  }
}
</script>

<style scoped lang="scss">
.upload-component-container {
  position: relative;
  width: 100%;
  height: 100%;
  background: url("../../assets/images/upload-component-bg.png") no-repeat center;
  background-size: cover;
}

.upload-component-container-no-bg {
  position: relative;
  width: 100%;
  height: 100%;
}

.upload-component-container-bg-gray {
  background-color: #dddddd;
  position: relative;
  width: 100%;
  height: 100%;
}

.upload-component-btn {
  position: relative;
  z-index: 2;
}

.upload-component-overlay {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  background: #2c3e50;
  position: absolute;
  opacity: 0.6;
}

.button-add-wrapper {
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
}
</style>
