import Store from "../store/index"

const SidebarStore = {
    namespaced: true,
    state: {
        isShowSidebar: true,
        isReportList: true,
        isCreateReport: false,
        isPreviewReport: false,
        isAllowMarker: false
    },
    getters: {},
    mutations: {
        toggleSidebar(state, value) {
            state.isShowSidebar = value
        },
        toggleOpenList(state, value) {
            state.isShowSidebar = true
            state.isReportList = true
            state.isCreateReport = false
            state.isPreviewReport = false
        },
        toggleCreateReport(state, value) {
            state.isShowSidebar = value
            state.isCreateReport = value
            state.isAllowMarker = value
            Store.commit('ReportStore/setShowInfo', false)
            state.isReportList = false
            state.isPreviewReport = false
        },
        togglePreviewReport(state, value) {
            state.isShowSidebar = value
            state.isPreviewReport = value
            state.isCreateReport = false
            state.isReportList = false
        },
        setIsCreateReport(state, value) {
            state.isCreateReport = value
        },
        setIsShowSidebar(state, value) {
            state.isShowSidebar = value
        },
        setIsShowReportList(state, value) {
            state.isReportList = value
        },
        setIsPreviewReport(state, value) {
            state.isPreviewReport = value
        },
        setIsAllowMarker(state, value) {
            state.isAllowMarker = value
        }
    },
    actions: {},
}

export default SidebarStore
