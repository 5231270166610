<template>
	<div>
		<div v-if="!isLoading" class="min-vh-100 w-50 mx-auto d-flex flex-column justify-content-center">
			<div v-if="!tokenNotValid" class="card w-75 p-4 mx-auto bg-white-transparent shadow">
				<div class="card-body">
					<h4 class="text-custom-primary mb-3 font-weight-bold">Aktivasi Akun Berhasil</h4>

					<p class="mb-3">
						Silahkan masuk kembali dengan akun anda.
					</p>

					<router-link v-if="!isFormSimtaru" :to="{ name: 'Login' }" class="btn btn-custom-primary rounded-pill px-4">
						Masuk
					</router-link>

          <a v-else :href="this.urlLoginSimtaru" class="btn btn-custom-primary rounded-pill px-4">
            Masuk
          </a>
				</div>
			</div>
			<div v-else class="card w-75 p-4 mx-auto bg-white-transparent shadow">
				<div class="card-body">
					<h4 class="text-custom-primary font-weight-bold mb-4">Token Tidak Valid</h4>

					<router-link :to="{ name: 'Login' }" class="btn btn-custom-primary rounded-pill px-4">
						Kembali
					</router-link>
				</div>
			</div>
		</div>

		<div v-else class="loading-fullscreen">
			<LoadingComponent />
		</div>
	</div>
</template>

<script>
import {ActivateAccount} from "../../../service/AuthService";
import LoadingComponent from "../../../components/general/LoadingComponent";

export default {
	name: "ActivateEmailComponent",
	components: {LoadingComponent},
	data() {
		return {
			token: '',
			tokenNotValid: false,
			isLoading: false,
      isFormSimtaru: false,
      urlLoginSimtaru: 'https://kitorang.papuabaratprov.go.id/account/login/?next=/'
		}
	},
	mounted() {
		this.token = this.$route.query.token
    this.isFormSimtaru = this.$route.query.ref

		if (!this.token) {
			this.tokenNotValid = true
			return
		}

		this.activateAccount()
	},
	methods: {
		async activateAccount() {
			this.isLoading = true

			try {
				const res = await ActivateAccount(this.token)

				if (res) {
					this.tokenNotValid = false

					setTimeout(() => {
            if (this.isFormSimtaru) window.location.href = this.urlLoginSimtaru
            else this.$router.push({ name: 'Login' })
					}, 10000)
				}
			} catch (e) {
				this.tokenNotValid = true

				this.$store.dispatch('NotificationStore/openNotification', {
					type: 'error',
					message: e.response.data.message
				})
			}

			this.isLoading = false
		}
	}
}
</script>
