<template>
	<div class="lds-ring" :class="{ 'lds-ring-small': isSmall }" style="display: flex; justify-content: center; align-items: center; min-height: 30px;">
		<div></div>
		<div></div>
		<div></div>
		<div></div>
	</div>
</template>

<script>
export default {
	name: "LoadingComponent",
	props: {
		isSmall: {
			type: Boolean,
			required: false,
			default: false
		}
	}
}
</script>

<style scoped>
.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 64px;
	height: 64px;
	margin: 8px;
	border: 8px solid #fff;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: rgba(41, 87, 164, 1) transparent transparent transparent;
}

.lds-ring-small div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 34px;
	height: 34px;
	margin: 8px;
	border: 5px solid #fff;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: rgba(41, 87, 164, 1) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}

@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
