<template>
  <div class="h-100">
    <Hooper
        ref="hooper"
        :settings="hooperSettings">
      <Slide v-for="val in images" :key="val.id">
        <div
            :style="{ backgroundImage: `url(${val.url})`, backgroundSize: 'contain', position: 'relative'}"
            class="w-100 standard-thumbnail position-relative">
          <button
              v-if="deleteBtn"
              @click.prevent="handleDelete(val)"
              style="border-radius: 50%"
              class="btn btn-danger btn-remove btn-sm ml-2"><i class="fa fa-trash"></i></button>
        </div>
      </Slide>
      <Navigation
          v-if="images.length > 1"
          slot="hooper-addons">
        <h5 slot="hooper-next"><i class="fa fa-chevron-circle-right"></i></h5>
        <h5 slot="hooper-prev"><i class="fa fa-chevron-circle-left"></i></h5>
      </Navigation>
    </Hooper>
  </div>
</template>

<script>
import {Hooper, Slide, Navigation} from 'hooper'
import 'hooper/dist/hooper.css'

export default {
  name: "ImageCarouselComponent",
  components: {
    Hooper,
    Slide,
    Navigation
  },
  props: {
    images: {
      type: Array,
      required: true
    },
    deleteBtn: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleDelete(val) {
      this.$refs.hooper.slideTo(0)
      this.$emit('remove', val)
    }
  },
  data() {
    return {
      hooperSettings: {
        itemsToShow: 1,
        centerMode: true
      }
    }
  }
}
</script>

<style scoped>
.hooper {
  height: 100% !important;
}

.standard-thumbnail {
  width: 100%;
  padding-bottom: 70%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.btn-remove {
  position: absolute;
  top: 15px;
  right: 15px;
}
</style>
