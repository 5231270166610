<template>
	<div class="app-container container-fluid py-5">
		<div class="row mb-2">
			<div class="col-6 mb-3 text-left d-flex flex-row align-items-center">
				<h4 class="font-weight-bold m-0">Manajemen Pengguna</h4>
				<button
					@click="handleFilterType('admin')"
					:class="[ query.type && query.type === 'admin' ? ' btn-yellow-bright' : 'btn-secondary']"
					class="btn btn-sm rounded-pill px-3 mx-3">Admin
				</button>
				<button
					@click="handleFilterType('pelapor')"
					:class="[ query.type && query.type === 'pelapor' ? ' btn-yellow-bright' : 'btn-secondary']"
					class="btn btn-sm rounded-pill px-3">Pelapor
				</button>
			</div>
			<div class="col-6 text-right">
				<router-link
					:to="{name: 'UserAdd'}"
					class="btn btn-sm btn-yellow rounded-pill px-3 font-weight-bold">
					<i class="fa fa-user-plus mr-2"></i> Tambah
				</router-link>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<table class="table">
					<tr class="bg-light">
						<th>
							NO ID
							<button @click="sortButtonClick('id')" class="btn btn-sm btn-link">
								<span><i class="fa fa-sort text-secondary"></i></span>
							</button>
						</th>
						<th>
							NAMA
							<button @click="sortButtonClick('fullname')" class="btn btn-sm btn-link">
								<span><i class="fa fa-sort text-secondary"></i></span>
							</button>
						</th>
						<th>
							LEVEL
							<button @click="sortButtonClick('role')" class="btn btn-sm btn-link">
								<span><i class="fa fa-sort text-secondary"></i></span>
							</button>
						</th>
						<th>
							NOMOR PONSEL
							<button @click="sortButtonClick('mobile')" class="btn btn-sm btn-link">
								<span><i class="fa fa-sort text-secondary"></i></span>
							</button>
						</th>
						<th>
							EMAIL
							<button @click="sortButtonClick('email')" class="btn btn-sm btn-link">
								<span><i class="fa fa-sort text-secondary"></i></span>
							</button>
						</th>
						<th>
							STATUS
							<button @click="sortButtonClick('status')" class="btn btn-sm btn-link">
								<span><i class="fa fa-sort text-secondary"></i></span>
							</button>
						</th>
					</tr>
					<tbody>
					<tr v-for="val in users" :key="val.id" @click="redirectToDetail(val.id)" class="cursor-pointer">
						<td>
							{{ val.id }}
						</td>
						<td>
							{{ val.fullname }}
						</td>
						<td>
							{{ val.role.name }}
						</td>
						<td>
							{{ val.mobile }}
						</td>
						<td>
							{{ val.email }}
						</td>
						<td>
							{{ val.status }}
						</td>
					</tr>
					</tbody>
				</table>
				<LoadingComponent class="py-5" v-if="isLoading"/>
				<PaginationComponent
					class="my-5"
					:current-page="Number(page)"
					:total-data="totalData"
					:limit="limit"
					@change-page="handlePageChange"
				/>
			</div>
		</div>
	</div>
</template>
<script>
import LoadingComponent from "../../../components/general/LoadingComponent";
import {getListUser} from "../../../service/AuthService";
import PaginationComponent from "../../../components/general/PaginationComponent";

export default {
	name: 'UserListScreen',
	components: {PaginationComponent, LoadingComponent},
	data() {
		return {
			isLoading: false,
			totalData: 0,
			users: [],
			limit: 15,
		}
	},
	computed: {
		query() {
			return this.$route.query
		},
		page() {
			if (this.query && this.query.page) return this.query.page
			return 1
		}
	},
	methods: {
		handleFilterType(e) {
      const {type} = this.query
      let queryType = e
      if(type && type === e) {
        queryType = ''
      }

			this.$router.push({
				name: 'UserList',
				query: {
					...this.query,
					...{
						type: queryType
					}
				}
			})
		},
		async getUser() {
			try {
				const parameter = {
					filter: {}
				}

				const {type, sort, sortType} = this.query

				if (type) {
					parameter.filter['where[role][name]'] = type.toUpperCase()
				}

				if (sort && sortType) {
					parameter.filter[`order[${sort}]`] = sortType.toUpperCase()
				}

				parameter.filter = {
					...parameter.filter,
					...{
						take: this.limit,
						skip: this.page === 1 ? 0 : (this.page - 1) * this.limit
					}
				}

				const res = await getListUser(parameter)
				if (res && res.data && res.data.data.length) {
					this.users = res.data.data
					this.totalData = res.data.total
				}

			} catch (e) {
				const message = e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message
				this.$helper.errorNotification(message)
			}
		},
		handlePageChange(e) {
			this.$router.push({
				name: 'UserList',
				query: {
					...this.query,
					...{
						page: e
					}
				}
			})
		},
		redirectToDetail(id) {
			this.$router.push({
				name: 'UserDetail',
				params: {
					id: id
				}
			})
		},
		sortButtonClick(field) {
			let sortType = this.query.sortType

			if (sortType === 'ASC') sortType = 'DESC'
			else if (sortType === 'DESC') sortType = 'ASC'
			else sortType = 'ASC'

			let sortParams = {
				sort: field,
				sortType: sortType
			}

			this.$router.push({
				name: 'UserList',
				query: {
					...this.query,
					...sortParams
				}
			})
		}
	},
	watch: {
		'$route.query': function () {
			this.getUser()
		}
	},
	mounted() {
		this.getUser()
	}
}
</script>
<style lang="scss" scoped></style>
