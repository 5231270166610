<template>
	<div class=" d-flex flex-column align-items-center">
		<div class="mb-3">
			<img src="../../assets/images/logo-simtaru/logo-papua.png">
		</div>
		<div class="text-center">
		  <h2 class="font-weight-bold text-blue">
        Laporkitong Papua Barat
      </h2>
		</div>
	</div>
</template>

<script>
export default {
	name: "DynamicLogoComponent"
}
</script>

<style scoped>

</style>
