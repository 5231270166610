<template>
	<li @click="updateNotificationRead()"
	    :class="[!isUnread ? 'is-read' : '']"
	    class="list-group-item cursor-pointer">
		<div class="date">
			{{ $helper.formatDate(data.createAt)}}
		</div>
		<div
			:class="[isUnread ? 'font-weight-bold' : '']"
			class="content">
			{{ data.content }}
		</div>
		<div v-if="!isUnread" class="read-icon">
			<i class="fa fa-check"></i>
		</div>
	</li>
</template>
<script>
import {updateNotification} from "../../../service/NotificationService";

export default {
	name: 'NotificationItemComponent',
	props: {
		data: {
			type: Object,
			required: true
		}
	},
	computed: {
		isUnread() {
			return this.data.status !== 'read'
		}
	},
	methods: {
		async updateNotificationRead() {
			try {
				await updateNotification({
					id: this.data.id,
					title: this.data.title,
					content: this.data.content,
					status: 'read',
					createAt: this.data.createAt
				})

				this.data.status = 'read'

				this.handleClick()
			} catch (e) {
				const message = e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message
				this.$helper.errorNotification(message)
			}
		},
		handleClick() {
			this.$router.push({name: 'ReportDetail', params: {id: this.data.report.id}}).catch()
		}
	}
}
</script>
<style scoped lang="scss">
.read-icon {
	position: absolute;
	top: 45%;
	right: 15px;
	color: #FF5233
}
</style>
