<template>
  <div>
    <UserHeaderComponent/>
    <router-view></router-view>
  </div>
</template>
<script>
import UserHeaderComponent from "../components/UserHeaderComponent";

export default {
  name: 'MapLayout',
  components: {UserHeaderComponent}
}
</script>
<style lang="scss" scoped></style>
