<template>
  <div
      v-click-outside="closeDropdown"
      class="d-inline-block position-relative">
    <div @click="isShow = !isShow"
         class="px-3 cursor-pointer text-white">
      <span class="text-yellow"><i class="fa fa-user-circle h5 mr-2"></i></span>
      {{ fullName }}
    </div>
    <div
        v-if="isShow"
        :class="[isAdmin ? 'admin' : '']"
        class="dropdown-menu show" aria-labelledby="navbarDropdown">
      <router-link :to="{name: 'EditProfile'}" class="dropdown-item">
        <i class="fa fa-user-circle"></i> Profile
      </router-link>
      <div class="dropdown-divider"></div>
      <router-link :to="{ name: 'Logout' }" class="dropdown-item">
        <span><i class="fa fa-sign-out-alt"></i></span>
        Keluar
      </router-link>
    </div>
  </div>

</template>

<script>
import {mapState, mapGetters} from "vuex";

export default {
  name: "UserProfileModal",
  data() {
    return {
      isShow: false
    }
  },
  computed: {
    ...mapState('UserStore', ['userData']),
    ...mapGetters('UserStore', ['isAdmin']),
    fullName() {
      if (this.userData) return this.userData.fullname
      return '-'
    }
  },
  watch: {
    '$router': function () {
      this.isShow = false
    }
  },
  methods: {
    closeDropdown() {
      this.isShow = false
    }
  }
}
</script>
<style scoped lang="scss">
.dropdown-menu {
  top: 120%;
  min-width: 250px;
  background-color: #2957A4;
  color: white;

  &.admin {
    background-color: #3B3B3B;
  }

  .dropdown-item, .dropdown-item:hover, .dropdown-item:active, .dropdown-item:visited {
    background-color: transparent;
    color: white;
  }
}
</style>
