<template>
	<FadeTransitionComponent>
		<ModalComponent :is-show="isOpen" :is-header="false" :is-center="true" @close="closeNotification">
			<template v-slot:body>
				<div style="text-align: center">
					<h4 v-if="withHeaderNotificationText" class="text-custom-primary font-weight-bold">Notifikasi</h4>

					<sweetalert-icon :icon="typeIcon" />

					<p class="mb-4" v-html="message"></p>

					<div class="mb-4">
						<slot name="body"></slot>
					</div>

					<div class="d-flex justify-content-center w-50 mx-auto">
						<button class="btn btn-sm btn-custom-primary px-3" @click.prevent="confirmButtonClick">
							Oke
						</button>
					</div>
				</div>
			</template>
		</ModalComponent>
	</FadeTransitionComponent>
</template>

<script>
import FadeTransitionComponent from "../FadeTransitionComponent";
import ModalComponent from "../general/ModalComponent";
import {mapState, mapActions} from "vuex"

export default {
	name: "PopUpComponent",
	components: {ModalComponent, FadeTransitionComponent},
	props: {
		withHeaderNotificationText: {
			type: Boolean,
			required: false,
			default: true
		}
	},
	computed: {
		...mapState('NotificationStore', ['message', 'isOpen', 'typeIcon'])
	},
	methods: {
		...mapActions('NotificationStore', ["closeNotification"]),
		confirmButtonClick: function () {
			this.closeNotification()
		}
	}
}
</script>

<style scoped>

</style>
