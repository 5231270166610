<template>
	<div class="min-vh-100 w-50 mx-auto d-flex flex-column justify-content-center">
		<div v-if="!tokenIsInvalid">
			<div v-if="!isRequestSent" class="card w-60 p-4 mx-auto bg-white-transparent shadow">
				<div class="card-body">
					<div class="row">
						<div class="col-sm-12 text-center mb-3">
							<DynamicLogoComponent />
						</div>

						<div class="col-sm-12 mb-3">
							<form @submit.prevent="doChangePassword">
								<div class="form-group">
									<CustomInputPillComponent v-model="data.password" type="password" id="password" placeholder="Kata Sandi" />
								</div>

								<div class="form-group">
									<CustomInputPillComponent v-model="data.rePassword" type="password" id="rePassword" placeholder="Ulangi Kata Sandi" />
								</div>

								<div class="form-group">
									<button type="submit" class="btn btn-custom-primary rounded-pill w-100">
										Ubah Kata Sandi
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>

			<div v-else class="card w-75 p-4 mx-auto bg-white-transparent shadow">
				<div class="card-body">
					<h4 class="text-custom-primary mb-3 font-weight-bold">Ubah Kata Sandi Berhasil.</h4>

					<p class="mb-3">
						Silahkan masuk kembali dengan akun anda.
					</p>

					<router-link :to="{ name: 'Login' }" class="btn btn-custom-primary rounded-pill px-4">
						Kembali
					</router-link>
				</div>
			</div>
		</div>

		<div v-else class="card w-75 p-4 mx-auto bg-white-transparent shadow">
			<div class="card-body">
				<h4 class="text-custom-primary font-weight-bold mb-4">Token Tidak Valid</h4>

				<router-link :to="{ name: 'Login' }" class="btn btn-custom-primary rounded-pill px-4">
					Kembali
				</router-link>
			</div>
		</div>

		<div v-if="isLoading" class="loading-fullscreen">
			<LoadingComponent />
		</div>
	</div>
</template>

<script>
import {ConfirmResetPassword} from "../../../service/AuthService";
import DynamicLogoComponent from "../../../components/general/DynamicLogoComponent";
import CustomInputPillComponent from "../../../components/input/CustomInputPillComponent";
import LoadingComponent from "../../../components/general/LoadingComponent";

export default {
	name: "ConfirmResetPasswordComponent",
	components: {LoadingComponent, CustomInputPillComponent, DynamicLogoComponent},
	data() {
		return {
			data: {
				token: '',
				password: '',
				rePassword: ''
			},
			isLoading: false,
			isRequestSent: false,
			tokenIsInvalid: false
		}
	},
	mounted() {
		this.data.token = this.$route.query.token

		if (!this.data.token) {
			this.tokenIsInvalid = true
			return
		}
	},
	methods: {
		async doChangePassword() {
			this.isLoading = true

			try {
				const res = await ConfirmResetPassword(this.data)

				if (res) {
					this.isRequestSent = true
				}
			} catch (e) {
				let messages = e.response.data.message
				if (typeof messages === 'object') {
					messages = messages.join('<br>')
				}

				this.$store.dispatch('NotificationStore/openNotification', {
					message: messages,
					type: 'error'
				})
			}

			this.isLoading = false
		}
	}
}
</script>

<style scoped>

</style>
