<template>
  <div
      :class="[isShowSidebar ? 'active' : '']"
      class="report-info-panel">
    <div class="row">
      <div class="col-sm-4">
        <h3>{{ statisticReport.pelapor }}</h3>
        <p>Pelapor</p>
      </div>
      <div class="col-sm-4">
        <h3>{{ statisticReport.laporan }}</h3>
        <p>Laporan</p>
      </div>
      <div class="col-sm-4">
        <h3>{{ statisticReport.ditangani }}</h3>
        <p>Ditangani</p>
      </div>
    </div>
  </div>

</template>
<script>
import {statisticReport} from "../../service/ReportService";
import {mapMutations, mapState} from "vuex";

export default {
  name: 'ReportStatisticComponent',
  computed: {
    ...mapState('SidebarStore', ['isShowSidebar']),
    ...mapState('ReportStore', ['statisticReport'])
  },
  methods: {
    ...mapMutations('ReportStore', ['setStatisticReport']),
    async getStatistic() {
      try {
        let res = await statisticReport({})

        if (res && res.data) {
          this.setStatisticReport(res.data)
        }
      } catch (e) {
        this.$store.commit('NotificationStore/openNotification', {
          type: 'error',
          message: 'Error'
        })
      }
    }
  },
  mounted() {
    this.getStatistic()
  },
}
</script>
<style lang="scss" scoped>
p {
	margin-bottom: 0;
}

.report-info-panel {
  position: absolute;
  width: 600px;
  padding: 30px;
  margin: 40px 70px;
  color: white;
  border-radius: 7px;
  bottom: 0;
  background: rgba(37,155,120,0.8);
  left: 0;
  right: 0;
  &.active {
    left: 400px
  }
}
</style>
