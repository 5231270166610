<template>
	<div class="sidebar-container">
		<SidebarListReportComponent v-if="isReportList"
		                            :key="renderKey" />

		<SidebarCreateReportComponent v-if="isCreateReport" />

		<SidebarCreateReportPreviewComponent v-if="isPreviewReport"
		                                     :report-sent-status="sentReportStatus"
		                                     :new-report-data="newReportData"
		                                     :key="renderKey" />
	</div>
</template>

<script>
import SidebarListReportComponent from "./SidebarListReportComponent";
import SidebarCreateReportComponent from "./SidebarCreateReportComponent";
import SidebarCreateReportPreviewComponent from "./SidebarCreateReportPreviewComponent";
import {mapState, mapMutations} from "vuex";

export default {
	name: "SidebarComponent",
	components: {
    SidebarCreateReportPreviewComponent,
    SidebarCreateReportComponent, SidebarListReportComponent},
	data() {
		return {
			showListReport: false,
			isAutoOpenCreateReport: this.openCreateReport,
			sentReportStatus: false,
			renderKey: 1,
			newReportData: null
		}
	},
	computed: {
		...mapState('SidebarStore', ['isReportList', 'isCreateReport', 'isPreviewReport'])
	},
	methods: {
		...mapMutations('ReportStore', ['setMarkerActive']),
		openCreateReport() {
			this.setMarkerActive(false)
			this.$store.commit('SidebarStore/toggleCreateReport', true)
		}
	}
}
</script>

<style scoped lang="scss">
.sidebar-container {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	z-index: 1;
	background: white;
	width: 440px;
	overflow-x: hidden;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}
</style>
