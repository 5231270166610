<template>
  <div @click="setDataReport()" class="cursor-pointer">
    <div class="row mt-2">
      <div class="col-sm-12 d-flex justify-content-between">
        <p class="text-left">
          {{ $helper.formatDate(data.createAt) }} <br>
          {{ data.reportCategory ? data.reportCategory.name : '-' }}
        </p>
        <BadgeStatusComponent :data="data.status" />
      </div>
      <div class="col-sm-12 text-left">
        <strong>
          {{ data.reportType ? data.reportType.name : '-' }}
        </strong>
      </div>
    </div>
    <hr>
  </div>
</template>
<script>
import BadgeStatusComponent from "../../../components/BadgeStatusComponent";
import {mapMutations} from "vuex";

export default {
  name: 'SidebarReportItemComponent',
  components: {BadgeStatusComponent},
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    ...mapMutations('ReportStore', ['setSelectedReport','setShowInfo']),
    ...mapMutations('MapsStore', ['setDefaultCoordinate']),
    setDataReport() {
      this.data.coordinate = {
        lat: this.data.latitude,
        lng: this.data.longitude
      }

      this.setDefaultCoordinate(this.data.coordinate)
      this.setShowInfo(true)
      this.setSelectedReport(this.data)
    }
  }
}
</script>
<style lang="scss" scoped></style>
