<template>
	<CustomSelectComponent @input="handleInput" :value="value" add-class="mb-3" :options="categoryOptions" placeholder="Pilih Kategori" />
</template>

<script>
import {getReportCategory} from "../../service/LocationService";
import CustomSelectComponent from "./CustomSelectComponent";
import {mapState, mapMutations} from "vuex"

export default {
	name: "SelectReportCategoryComponent",
	components: {CustomSelectComponent},
  props: {
    value: {
      type: [Number, String],
      default: ''
    }
  },
	async mounted() {
		if (this.reportCategory.length) {
			this.categoryOptions = this.reportCategory
		} else {
			let res = await getReportCategory({})
			this.categoryOptions = res.data
			this.setReportCategory(res.data)
		}
	},
	data() {
		return {
			selected: '',
			categoryOptions: []
		}
	},
	computed: {
		...mapState('LocationStore', ['reportCategory']),
	},
	methods: {
		...mapMutations('LocationStore', ['setReportCategory']),
    handleInput(e) {
      this.$emit('input', this.categoryOptions.find(item => {
        return String(item.id) === String(e)
      }))
    }
	},
}
</script>

<style scoped>

</style>
