<template>
	<div class="d-flex justify-content-center">
		<a v-if="currentPage > 1" class="btn btn-custom-primary rounded-pill px-3 mr-3" @click.prevent="changePage(currentPage - 1)">
			<i class="fa fa-long-arrow-alt-left mr-2"></i>
			Prev
		</a>

		<a v-if="currentPage < countPage" class="btn btn-custom-primary rounded-pill px-3" @click.prevent="changePage(currentPage + 1)">
			Next
			<i class="fa fa-long-arrow-alt-right ml-2"></i>
		</a>
	</div>
</template>

<script>
export default {
	name: "PaginationSimpleComponent",
	props: {
		currentPage: {
			type: Number,
			default: 1
		},
		totalData: {
			type: Number,
		},
		limit: {
			type: Number
		}
	},
	computed: {
		countPage() {
			return Math.round(this.totalData / this.limit)
		}
	},
	methods: {
		changePage(page) {
			if (this.currentPage !== page) {
				this.$emit('change-page', page)
			}
		}
	}
}
</script>

<style scoped lang="scss">
a {
	cursor: pointer;
}

.active {
	position: relative;
	color: white;

	&:hover {
		color: white;
	}

	&:before {
		display: inline-block;
		position: absolute;
		top: 0;
		right: -50%;
		left: -50%;
		bottom: 0;
		margin: auto;
		z-index: -1;
		content: ' ';
		width: 30px;
		height: 30px;
		background: #717171;
		border-radius: 50px;
	}
}
</style>
