<template>
	<div>
		<div class="text-left">
			<div class="d-flex align-items-center">
				<p v-if="showLabel" class="mr-5">Tanggapan</p>

				<div v-if="!rating" class="d-flex">
					<div @click.prevent="submitRating(1)" class="cursor-pointer text-center d-flex align-items-center text-secondary">
						<h3><i class="fas fa-laugh"></i></h3>
						<p v-if="showLabel" class="ml-3">Puas</p>
					</div>
					<div @click.prevent="submitRating(2)" class="ml-5 cursor-pointer text-center d-flex align-items-center text-secondary">
						<h3><i class="fas fa-meh"></i></h3>
						<p v-if="showLabel" class="ml-3">Biasa aja</p>
					</div>
					<div @click.prevent="submitRating(3)" class="ml-5 cursor-pointer text-center d-flex align-items-center text-secondary">
						<h3><i class="fas fa-frown"></i></h3>
						<p v-if="showLabel" class="ml-3">Kecewa</p>
					</div>
				</div>

				<div v-else class="d-flex">
					<div v-if="rating === 1" class="cursor-pointer text-center d-flex align-items-center text-success">
						<h3><i class="fas fa-laugh"></i></h3>
						<p v-if="showLabel" class="ml-3">Puas</p>
					</div>
					<div v-else-if="rating === 2" class="ml-5 cursor-pointer text-center d-flex align-items-center text-warning">
						<h3><i class="fas fa-meh"></i></h3>
						<p v-if="showLabel" class="ml-3">Biasa aja</p>
					</div>
					<div v-else class="ml-5 cursor-pointer text-center d-flex align-items-center text-danger">
						<h3><i class="fas fa-frown"></i></h3>
						<p v-if="showLabel" class="ml-3">Kecewa</p>
					</div>
				</div>
			</div>
		</div>

		<div v-if="isLoading" class="loading-fullscreen">
			<LoadingComponent />
		</div>
	</div>
</template>

<script>
import LoadingComponent from "../../../../components/general/LoadingComponent";
import {sendRating} from "../../../../service/ReportService";
import {mapState} from "vuex";

export default {
	name: "ReportRatingComponent",
	components: {LoadingComponent},
	props: {
		id: {
			type: Number,
			required: true
		},
		rating: {
			type: [Number, String],
			required: false,
			default: 0
		},
		showLabel: {
			type: Boolean,
			required: false,
			default: true
		},
		showNotification: {
			type: Boolean,
			required: false,
			default: true
		}
	},
  computed: {
    ...mapState('UserStore', ['userData'])
  },
	data() {
		return {
			isLoading: false
		}
	},
	methods: {
		async submitRating(rating) {
      if(this.userData) {
        this.isLoading = true
        let body = {
          id: this.id,
          rating
        }

        try {
          let res = await sendRating(body)

          if (res) {
            if (this.showNotification) this.$helper.errorNotification('Berhasil mengirim tanggapan.', 'success')
            this.$emit('update-success')
          }
        } catch (e) {
          if (this.showNotification) this.$helper.errorNotification(e.response.data.message)
        }
        this.isLoading = false

      }else {
        await this.$router.push({name: 'Loading'})
      }
		}
	}
}
</script>

<style scoped>
	h3, p {
		margin-bottom: 0;
	}
</style>
