<template>
	<div
		v-click-outside="closeDropdown"
		class="button-notification position-relative">
		<div
			v-if="notification > 0"
			@click="isOpen = !isOpen"
			class="notification-badge cursor-pointer">{{ notification }}
		</div>
		<i
			@click="isOpen = !isOpen"
			class="fa fa-bell cursor-pointer"></i>
		<DropDownNotificationComponent
			v-if="isOpen"
		/>
	</div>
</template>
<script>
import {getUnreadNotification} from "../../../service/NotificationService";
import DropDownNotificationComponent from "./DropDownNotificationComponent";
import {io} from "socket.io-client"

let socket

export default {
	name: 'ButtonNotificationComponent',
	components: {DropDownNotificationComponent},
	data() {
		return {
			notification: 0,
			isOpen: false
		}
	},
	methods: {
		async getUnreadNotification() {
			try {
				const res = await getUnreadNotification()
				if (res && res.data) this.notification = res.data.unread
			} catch (e) {
				console.log('get notification error', e)
			}
		},
		closeDropdown() {
			this.isOpen = false
		},
		initSocket() {
			const accessToken = localStorage.getItem('accessToken')

			socket = io(process.env.VUE_APP_WEB_SOCKET, {
				extraHeaders: {
					Authorization: `Bearer ${accessToken}`
				}
			})

			socket.on('connect', () => {
				socket.emit('init');

				socket.on('notification', () => {
					this.getUnreadNotification()
				})
			})
		}
	},
	watch: {
		'$router': function () {
			this.isOpen = false
		}
	},
	mounted() {
		this.getUnreadNotification()
		this.initSocket()
	},
	beforeDestroy() {
		socket.disconnect();
	}
}
</script>
<style lang="scss" scoped>
.button-notification {
	color: white;
	display: inline-block;
	width: 50px;

	i {
		font-size: 1.2rem;
	}

	.notification-badge {
		font-size: .6rem;
		position: absolute;
		width: 20px;
		height: 20px;
		top: -10px;
		right: 5px;
		background-color: red;
		color: white;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
</style>
