<template>
  <CustomSelectComponent @input="handleInput" :value="value" add-class="mb-3" :options="typeOptions"
                         placeholder="Pilih Laporan"/>
</template>

<script>
import {getReportType} from "../../service/LocationService";
import CustomSelectComponent from "./CustomSelectComponent";
import {mapState, mapMutations} from "vuex"

export default {
  name: "SelectReportTypeComponent",
  components: {CustomSelectComponent},
  async mounted() {
    await this.getReportTypeOption()
  },
  props: {
    categoryId: {
      type: Number,
      required: false,
      default: ''
    },
    value: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      typeOptions: []
    }
  },
  methods: {
    ...mapMutations('LocationStore', ['setReportType']),
    async getReportTypeOption() {
      if (this.categoryId) {
        let res = await getReportType({}, this.categoryId)
        this.typeOptions = res.data
      }
    },
    handleInput(e) {
      this.$emit('input', this.typeOptions.find(item => {
        return String(item.id) === String(e)
      }))
    }
  },
  watch: {
    categoryId() {
      this.getReportTypeOption()
    }
  },
  computed: {
    ...mapState('LocationStore', ['reportType'])
  }
}
</script>

