<template>
	<div class="h-100">
		<div class="h-100">
			<div v-if="!isReportSent">
				<div class="px-4 py-3 shadow">
					<h5 class="text-custom-primary d-flex justify-content-between align-items-center">
						<strong>Buat Pengaduan</strong>
						<a @click.prevent="backToDashboard" class="cursor-pointer text-dark">
							<span><i class="fa fa-times"></i></span>
						</a>
					</h5>
				</div>
				<div class="image-carousel-container">
					<ImageCarouselComponent :images="report.images" />
				</div>
				<div class="px-4 py-3 text-left">
					<div class="mb-2">
						<small>Laporan</small>
						<p class="font-weight-bold">{{ report.reportType.name }}</p>
					</div>

					<div>
						<small>Kategori pelaporan</small>
						<p>{{ report.reportCategory.name }}</p>
					</div>
					<div>
						<small>Deskripsi</small>
						<p>{{ report.description }}</p>
					</div>
					<hr>

					<div class="mb-2">
						<small>Lokasi</small>
						<div class="d-flex align-items-center">
							<span><i class="fa fa-map-marker-alt mr-3"></i></span>
							<p>
								{{ `${report.latitude}, ${report.longitude}` }} <br>
								{{ report.location }}
							</p>
						</div>

						<p>
							{{ `${report.distance.name}, ${report.distance.id !== 1 ? report.direction.name : '-'}` }} <br>
							{{ `${report.province.name}, ${report.city.name}, ${report.district.name}, ${report.villages.name}` }}
						</p>
					</div>
					<hr>

					<div class="mb-2">
						<small>Pelapor</small>
						<p>{{ userData.fullname }}</p>
						<p>{{ report.institusiName }}</p>
						<p>{{ report.address }}</p>
					</div>
				</div>
			</div>

			<div v-else class="h-100 d-flex justify-content-center align-items-center">
				<div class="px-4 py-3 text-custom-primary">
					<h1 class="mb-3"><i class="fa fa-paper-plane"></i></h1>
					<h2 class="mb-3">Laporan Terkirim</h2>
					<button @click="backToDashboard" class="btn btn-yellow rounded-pill px-4 font-weight-bold">
						OK
					</button>
				</div>
			</div>
		</div>

		<div v-if="!isReportSent" class="col-sm-12 py-3">
			<button @click="editReport" class="btn btn-custom-primary rounded-pill mr-2 font-weight-bold">
				Ubah Laporan
			</button>
			<button @click="sendNewReport" class="btn btn-yellow rounded-pill font-weight-bold">
				<span><i class="fas fa-paper-plane mr-2"></i></span>
				Kirim
			</button>
		</div>

		<div v-if="isLoading" class="loading-fullscreen">
			<LoadingComponent />
		</div>
	</div>
</template>

<script>
import ImageCarouselComponent from "../../../components/general/ImageCarouselComponent";
import {mapState, mapMutations} from "vuex"
import {createReport} from "../../../service/ReportService";
import LoadingComponent from "../../../components/general/LoadingComponent";
import moment from "moment";
export default {
	name: "SidebarCreateReportPreviewComponent",
	components: {LoadingComponent, ImageCarouselComponent},
	props: {
		reportSentStatus: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			isReportSent: this.reportSentStatus,
			isLoading: false
		}
	},
	computed: {
		...mapState('UserStore', ['userData']),
		...mapState('ReportStore', ['offset','limit', 'report'])
	},
	methods: {
		...mapMutations('ReportStore', ['setReports', 'setOffset','setMarkerActive', 'resetReportData', 'setTemporaryMarker']),
		...mapMutations('MapsStore', [ 'setContext']),
		...mapMutations('SidebarStore', ['toggleCreateReport', 'toggleOpenList','setIsPreviewReport','setIsCreateReport']),
		backToDashboard() {
			location.href = location.origin
		},
		editReport() {
			this.setIsPreviewReport(false)
			this.setIsCreateReport(true)
		},
		async sendNewReport() {
			this.isLoading = true

			try {
				const data = this.report
				data.uuid = Number(`${this.userData.id}${moment().utc(true).format('YYYYMMDDHHmmss')}`)
				let res = await createReport(this.report)

				if (res) {
					this.isReportSent = true
					this.setReports([])
					this.setOffset(0)
					this.resetReportData()
					this.setContext(null)
					this.setTemporaryMarker([])
				}
			} catch (e) {

				let message = e.response.data ? e.response.data.message : 'Error'
				if (typeof message === 'object') {
					message = message.join('<br>')
				}

				this.$store.commit('NotificationStore/openNotification', {
					type: 'error',
					message: 'Error'
				})
			}

			this.isLoading = false
		}
	},
	mounted() {
		this.setMarkerActive(false)
	},
	beforeDestroy() {
		this.setMarkerActive(true)
	}
}
</script>

<style scoped lang="scss">
h5, p {
	margin-bottom: 0;
}

.image-carousel-container {
	max-height: 250px;
	overflow: hidden;
}
</style>
