export const sanitizePhoneNumber = (str) => {
    if (String(str[0]) === String(0)) return `62${str.substring(1)}`
    if (String(str[0]) === String('+')) return `62${str.substring(1)}`
    if (String(str[0]) === String('6') && String(str[1]) === String(2)) return `${str.substring(1)}`
    return `62${str}`
}

export const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
};

export const validateUsername = string => {
    const re = /^([a-zA-Z0-9]+)$/;
    return re.test(String(string));
};

export const revertPhoneNumber = str => {
    if(!str) return ''
    str = String(str)
    if (String(str[0]) === String('6') && String(str[1]) === String('2')) {
        return `0${str.substring(2)}`
    }
    return str
}
