import moment from "moment";
import store from '../store/index'
class Helper {
  formatDate(str, time = false) {
    if (str && time) return moment(str).format('DD MMM YYYY HH:mm')
    if (str) return moment(str).format('DD MMM YYYY')
    return '-'
  }

  formatDatetime(str, time = false) {
    if (str) {
        let time = moment.utc(str, 'HH:mm:ss')

        return time.toISOString()
    }
    return '-'
  }

  errorNotification(message, type = 'error') {
    store.commit('NotificationStore/openNotification', {
      type,
      message
    })
  }

  sanitizePhoneNumber = (str) => {
    if(!str) return ''
    if (String(str[0]) === String(0)) return `62${str.substring(1)}`
    if (String(str[0]) === String('+') && String(str[0]) === String('6') && String(str[1]) === String(2)) return `${str.substring(3)}`
    if (String(str[0]) === String('+')) return `62${str.substring(1)}`
    if (String(str[0]) === String('6') && String(str[1]) === String(2)) return `${str.substring(2)}`
    return `${str}`
  }

  validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  validateUsername = string => {
    const re = /^([a-zA-Z0-9]+)$/;
    return re.test(String(string));
  };

  revertPhoneNumber = str => {
    if(!str) return ''
    str = String(str)
    if (String(str[0]) === String('6') && String(str[1]) === String('2')) {
      return `0${str.substring(2)}`
    }
    return str
  }

}

export default new Helper()
