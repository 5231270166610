<template>
  <div id="page-detail">
    <div v-if="data && !isLoading">
      <div v-if="!updateState.isUpdating" class="page-list-container container-fluid">
        <div class="row mt-5">
          <div class="col-sm-12 text-left">
            <div class="d-flex justify-content-between align-items-center">
              <h4 class="font-weight-bold mb-0 mr-4">Detail Pengaduan</h4>

              <div class="d-flex justify-content-between" v-if="!isGeneratingReport">
                <div v-if="data.status.id === 3">
                  <button v-if="!isUpdatingState" @click="toggleUpdatingDetail"
                          class="btn btn-sm btn-yellow rounded-pill font-weight-bold px-3">
                    Edit Pengaduan
                  </button>

                  <button v-else @click="updateRejectedReport"
                          class="btn btn-sm btn-yellow rounded-pill font-weight-bold px-3">
                    <span><i class="fas fa-save mr-2"></i></span>
                    Simpan
                  </button>
                </div>

                <div v-if="!isUpdatingState && isAdmin">
                  <button @click="printDetail" class="btn btn-primary btn-sm rounded-pill px-3 font-weight-bold ml-3">
                    <span><i class="fas fa-print mr-2"></i></span>
                    Print Detail
                  </button>
                </div>
              </div>
            </div>

            <hr>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="w-100 d-flex justify-content-between align-items-center mb-2">
              <div class="text-left">
                <small>Laporan</small>
                <p><strong>{{ data.reportType ? data.reportType.name : '-' }}</strong></p>
              </div>

              <div class="d-flex">
                <div v-if="data.rating" class="d-flex">
                  <div :class="getReportRating.textClass">
                    <h3><i class="mr-3" :class="getReportRating.icon"></i></h3>
                  </div>
                </div>
                <BadgeStatusComponent :data="data.status"/>
              </div>
            </div>
            <div class="text-left mb-3">
              <small>Kategori Pelaporan</small>
              <p>{{ data.reportCategory ? data.reportCategory.name : '-' }}</p>
            </div>
            <div class="text-left mb-3">
              <small>Deskripsi</small>

              <p v-if="!isUpdatingState">
                {{ data.description ? data.description : '-' }} <br>
                {{ data.distance.id === 1 ? wordingAfterUpdateLocation : '' }}
              </p>
              <textarea v-else v-model="updateReportData.description" cols="30" rows="5" class="form-control">{{ data.description }}</textarea>
            </div>
            <hr>
            <div class="text-left">
              <div v-if="data.oldLocation">
                <small>Lokasi Terkoreksi</small>

                <div class="row m-0 mt-3">
                  <div class="col-auto pl-0 marker-container">
                    <i class="fa fa-map-marker-alt"></i>
                  </div>
                  <div class="col-11">
                    <p>
                      {{ data.latitude }} , {{ data.longitude }} <br/>
                      {{ data.location }}
                    </p>
                  </div>
                </div>
              </div>

              <small>Lokasi Pelaporan</small>

              <div v-if="data.oldLocation" class="row m-0 mt-3">
                <div class="col-auto pl-0 marker-container">
                  <i class="fa fa-map-marker-alt"></i>
                </div>
                <div class="col-11">
                  <p>
                    {{
                      `${data.oldLocation.distance.name} arah ${data.oldLocation.direction ? data.oldLocation.direction.name : ''} dari`
                    }} <br>
                    {{ data.oldLocation.latitude }} , {{ data.oldLocation.longitude }} <br/>
                    {{ data.oldLocation.location }}
                  </p>
                </div>
              </div>
              <div v-else class="row m-0 mt-3">
                <div class="col-auto pl-0 marker-container">
                  <i class="fa fa-map-marker-alt"></i>
                </div>
                <div class="col-11">
                  <p>
                    {{ `${data.distance.name} arah ${data.direction ? data.direction.name : ''} dari` }} <br>
                    {{ data.latitude }} , {{ data.longitude }} <br/>
                    {{ data.location }}
                  </p>
                </div>
              </div>
              <div>
                <div class="row m-0">
                  <div class="col-9 p-0">
                    <p>
                      Provinsi {{ data.province.name }}, Kabupaten {{ data.regencies.name }}, <br/>
                      Distrik {{ data.district.name }}, Kampung {{ data.villages.name }}
                    </p>
                  </div>
                  <div v-if="isAdmin && isLocationNotExactly && isDataManager"
                       class="col-3 p-0 d-flex justify-content-end align-items-center">
                    <button @click="updateLocation" class="btn btn-custom-primary rounded-pill px-3">Perbarui Lokasi
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <div v-if="isAdmin" class="text-left">
              <small>
                Nama Pelapor
              </small>
              <p>
                {{ data.user.fullname }}
              </p>
              <small>
                Institusi
              </small>
              <p>
                {{ data.institusi && data.institusi.name ? data.institusi.name : '-' }}
                <br>
                {{ data.institusiName }}
                <br>
                {{ data.address }}
              </p>
              <small>
                Nomor Ponsel sewaktu pelaporan
              </small>
              <p>
                {{ data.mobile ? $helper.revertPhoneNumber(data.mobile) : '-' }}
              </p>
              <small>
                Nomor Ponsel terbaru
              </small>
              <p>
                {{ $helper.revertPhoneNumber(data.user.mobile) }}
              </p>
              <small>
                Email terbaru
              </small>
              <p>
                {{ data.user.email }}
              </p>
              <hr>
            </div>

            <div v-if="isShowKesimpulanForm && isAdmin">
              <ReportSpatialVerificationComponent :readonly="true"
                                                  :indikasi="spatialData.indication"
                                                  :existing-data="data.spatial"
                                                  v-model="spatialData"/>
            </div>

            <div class="text-left">
              <div v-if="!isAdmin" class="mb-2">
                <small>Status</small>
                <p class="font-weight-bold">Laporan sedang ditangani pihak terkait</p>
              </div>
              <div v-else class="mb-3">
                <small>
                  Status Terakhir
                </small>
              </div>
              <ReportStatusItemComponent
                  v-for="val in data.reportStatus"
                  :data="val"
                  :key="val.id"
              />
            </div>

            <div v-if="isAdmin && reportStatusIsNotFinish && validateUserRole && !isGeneratingReport" class="text-left">
              <hr>
              <ReportSpatialVerificationComponent v-if="isShowFormKesimpulan" v-model="spatialData"/>

              <small v-if="isStatusVerificationAdminSuccess">Catatan Untuk Supervisor</small>
              <small v-else>Perbarui Status</small>

              <div class="form-group">
                <textarea v-model="notes" class="form-control" id="" cols="30" rows="5"
                          :placeholder="placeholder"></textarea>
              </div>

              <div v-if="isCanSubmit" class="form-group text-right">
                <button
                    @click="submitReport({id: val.id, name: val.name})"
                    v-for="val in activeStatus"
                    :key="val.id"
                    class="btn btn-sm rounded-pill px-3"
                    :class="[ val.class ? val.class : 'btn-yellow']"
                    :disabled="checkAnyDataFilled"
                >
                  <strong>{{ val.name }}</strong>
                </button>
              </div>
              <div v-else class="form-group text-right">
                <button class="btn btn-sm btn-dark rounded-pill px-3" disabled>
                  Ubah lokasi menjadi lokasi sesungguhnya untuk memproses
                </button>
              </div>
            </div>

            <hr v-if="isShowKesimpulanButton && isAdmin">
            <div v-if="isShowKesimpulanButton && isAdmin" class="form-group text-left">
              <button @click="isShowReportSummaryModal = true" class="btn btn-sm btn-primary rounded-pill px-3">
                <span><i class="fas fa-print mr-2"></i></span>
                <strong>Kesimpulan</strong>
              </button>
            </div>

            <ReportRatingComponent v-if="isShowRating"
                                   class="mt-4"
                                   :id="data.id"
                                   @update-success="getReport"
                                   :rating="data.rating"/>
          </div>
          <div v-if="!isGeneratingReport" class="col-sm-6">
            <div v-if="!isUpdatingState">
              <div
                  v-if="data.images.length">
                <ImageCarouselComponent :images="data.images" :delete-btn="isUpdatingState"/>
              </div>
              <div v-else
                   :style="{backgroundImage: `url(${imgPlaceholder})`}"
                   class="standard-thumbnail"
              ></div>
            </div>

            <div v-else>
              <UploadComponent :images="data.images"
                               :delete-btn="isUpdatingState"
                               style="height: 400px"
                               @uploaded="handleUpdateInput($event, 'images')"/>
            </div>
          </div>
          <div v-else class="col-sm-6">
            <hr>
            <div class="d-block">
              <img v-for="(item, index) in data.images"
                   :src="item.url"
                   :key="index"
                   style="page-break-inside: avoid; break-inside: avoid; display: block;"
                   class="mb-5">
            </div>
          </div>
        </div>
      </div>
      <div v-else class="maps-container">
        <UpdateLocationComponent :data="data" @update-success="closeUpdateLocation"/>
        <GoogleMapsComponent
            :is-edit="true"
        />
      </div>
      <ModalPrintPreviewComponent
          v-if="isShowModalPrint"
          :data="data"
          @close="isShowModalPrint = false"
      />
      <ModalReportSummaryPrintComponent
          v-if="isShowReportSummaryModal"
          :data="data"
          @close="isShowReportSummaryModal = false"
      />
    </div>
    <div
        v-if="isLoading"
        class="page-list-container min-vh-100 d-flex align-items-center justify-content-center">
      <LoadingComponent/>
    </div>
  </div>
</template>

<script>
import UserHeaderComponent from "../../components/UserHeaderComponent";
import {Hooper, Slide, Navigation} from 'hooper'
import ImageCarouselComponent from "../../../components/general/ImageCarouselComponent";
import FooterComponent from "../../../components/general/FooterComponent";
import {createReport, findReportById, updateReport, createSpatialVerification} from "../../../service/ReportService";
import LoadingComponent from "../../../components/general/LoadingComponent";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import BadgeStatusComponent from "../../../components/BadgeStatusComponent";
import ReportStatusItemComponent from "./components/ReportStatusItemComponent";
import SelectStatusComponent from "../../../components/input/SelectStatusComponent";
import {getStatus} from "../../../service/LocationService";
import GoogleMapsComponent from "../../../components/GoogleMapsComponent";
import UpdateLocationComponent from "./components/UpdateLocationComponent";
import CustomInputComponent from "../../../components/input/CustomInputComponent";
import CustomInputPillComponent from "../../../components/input/CustomInputPillComponent";
import UploadComponent from "../../../components/general/UploadComponent";
import ReportRatingComponent from "./components/ReportRatingComponent";
import {UserRole} from "../../../const/UserRole";
import ModalPrintPreviewComponent from "./components/ModalPrintPreviewComponent";
import ReportSpatialVerificationComponent from "./components/ReportSpatialVerificationComponent";
import ModalReportSummaryPrintComponent from "./components/ModalReportSummaryPrintComponent";

const DONE = [8, 9]
const SHOW_RATING_STATUS = [6, 8, 9]
const VERIFICATION_SUCCESS = [4, 5, 6, 7, 8, 9]

export default {
  name: "ReportDetailScreen",
  components: {
    ModalReportSummaryPrintComponent,
    ReportSpatialVerificationComponent,
    ModalPrintPreviewComponent,
    ReportRatingComponent,
    UploadComponent,
    CustomInputPillComponent,
    CustomInputComponent,
    UpdateLocationComponent,
    GoogleMapsComponent,
    SelectStatusComponent,
    ReportStatusItemComponent,
    BadgeStatusComponent,
    LoadingComponent,
    FooterComponent,
    ImageCarouselComponent,
    UserHeaderComponent,
    Hooper,
    Slide,
    Navigation
  },
  data() {
    return {
      data: null,
      isLoading: true,
      notes: '',
      placeholder: '',
      isUpdatingState: false,
      wordingAfterUpdateLocation: 'Lokasi pelaporan sudah diperbarui.',
      updateReportData: {
        images: [],
        description: ''
      },
      isGeneratingReport: false,
      isShowModalPrint: false,
      spatialData: {
        data: [],
        indication: ''
      },
      isShowReportSummaryModal: false,
      isShowFormKesimpulan: false,
      isShowKesimpulanForm: false,
      isShowKesimpulanButton: false
    }
  },
  methods: {
    ...mapActions('NotificationStore', ['openNotification']),
    ...mapMutations('LocationStore', ['setStatus']),
    ...mapMutations('ReportStore', ['setUpdateState', 'setMarkerActive', 'setTemporaryMarker']),
    ...mapMutations('MapsStore', ['setAllowCreateMarker', 'setDefaultCoordinate', 'resetDefaultCoordinate']),
    ...mapMutations('SidebarStore', ['setIsAllowMarker']),
    async getReport() {
      this.isLoading = true
      try {
        const res = await findReportById(this.$route.params.id)
        if (res && res.data) {
          this.data = {...res.data}
          this.data.notes = ""

          this.spatialData.indication = res.data.indikasi

          if (this.data.status.id === 7) this.placeholder = "Rekomendasi :"
          else this.placeholder = "Catatan :"
        }
      } catch (e) {
        await this.openNotification({
          message: e.response.data.message,
          type: 'error'
        })
      }
      this.isLoading = false
    },
    handleInput(e) {
      this.status = e
    },
    async submitReport(val) {
      try {

        if(!this.notes) {
          throw new Error('Comment/Rekomendasi/Deskripsi Wajib diisi')
        }

        if (this.data.status.id === 2) {
          if (this.checkAnyDataFilled) {
            this.$helper.errorNotification('Harap isi hasil verifikasi spatial', 'warning')
            return
          }

          const createSpatialPayload = {
            report: {
              id: this.data.id
            },
            images: this.spatialData.data
                .filter((item) => {
                  return item.file.length && item.caption.length && item.description.length
                })
                .map((item) => {
                  return {
                    file: item.file[0],
                    description: item.description,
                    caption: item.caption
                  }
                })
          }
          await createSpatialVerification(createSpatialPayload)
          this.data.indikasi = this.spatialData.indication
        }

        if (!this.data.location) this.data.location = 'generated'
        this.data.mobile = Number(this.$helper.sanitizePhoneNumber(this.data.mobile))
        this.data.status = val
        this.data.notes = this.notes
        await createReport(this.data)
        this.$helper.errorNotification('Report sudah diperbarui', 'success')
        await this.getReport()
        this.notes = ""
      } catch (e) {
        const message = e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message
        this.$helper.errorNotification(message)
      }
    },
    async getStatus() {
      if (!this.status.length) {
        let res = await getStatus({})
        if (res && res.data) this.setStatus(res.data)
      }
    },
    updateLocation() {
      this.setUpdateState({
        isUpdating: true,
        data: {
          longitude: '',
          latitude: '',
          location: ''
        },
        oldData: this.data
      })

      this.setDefaultCoordinate({
        lat: this.data.latitude,
        lng: this.data.longitude
      })

      this.setTemporaryMarker(Array.of({
        latitude: this.data.latitude,
        longitude: this.data.longitude
      }))

      this.setAllowCreateMarker(true)
      this.setIsAllowMarker(true)
      this.setMarkerActive(false)
    },
    closeUpdateLocation() {
      this.setUpdateState({
        isUpdating: false,
        data: {
          longitude: '',
          latitude: '',
          location: ''
        }
      })

      this.resetDefaultCoordinate()

      this.setAllowCreateMarker(false)
      this.setIsAllowMarker(false)
      this.setMarkerActive(true)
      this.getReport()
    },
    toggleUpdatingDetail() {
      this.isUpdatingState = !this.isUpdatingState
    },
    async updateRejectedReport() {
      this.isLoading = true

      let data = this.data
      data.images = this.updateReportData.images
      data.description = this.updateReportData.description

      try {
        let res = await updateReport(data)

        if (res && res.data) {
          this.$helper.errorNotification('Berhasil memperbarui data.', 'success')
          this.isUpdatingState = false
          this.getReport()
        }
      } catch (e) {
        this.$helper.errorNotification(e.response.data.message)
      }

      this.isLoading = false
    },
    handleUpdateInput(e, key) {
      let updateReportData = {...this.updateReportData}
      updateReportData[key] = e
      this.updateReportData = updateReportData
    },
    findStatus(id) {
      return this.status.find(item => {
        return item.id === id
      })
    },
    printDetail() {
      if (this.userData) {
        this.isShowModalPrint = true
      } else {
        this.$router.push({name: 'Login'})
      }
    }
  },
  computed: {
    ...mapGetters('UserStore', ['isAdmin', 'isDataManager']),
    ...mapState('UserStore', ['userData']),
    ...mapState('LocationStore', ['status']),
    ...mapState('ReportStore', ['updateState']),
    ...mapGetters('ReportStore', ['reports']),
    isCanSubmit() {
      return !this.isLocationNotExactly || this.data.status.id === 10
    },
    imgPlaceholder() {
      return require('../../../assets/images/placeholder-image.png')
    },
    activeStatus() {
      let statusData = []
      if (this.data && this.status.length) {
        const {status} = this.data
        if (status.id === 1 || status.id === 11) {
          const status2 = this.findStatus(2)
          status2.class = 'btn-yellow mt-2'

          const status3 = this.findStatus(3)
          status3.class = 'btn-danger ml-3 mt-2'

          statusData.push(status2)
          statusData.push(status3)
        }
        if (status.id === 2) {
          statusData.push(this.findStatus(4))
        }
        if (status.id === 4) {
          const status2 = this.findStatus(5)
          status2.class = 'btn-yellow mt-2'

          const status3 = this.findStatus(6)
          status3.class = 'btn-danger ml-3 mt-2'

          statusData.push(status2)
          statusData.push(status3)
        }
        if (status.id === 5) {
          statusData.push(this.findStatus(7))
        }
        if (status.id === 7) {
          const status2 = this.findStatus(8)
          status2.class = 'btn-yellow mt-2'

          const status3 = this.findStatus(9)
          status3.class = 'btn-success ml-3 mt-2'

          statusData.push(status2)
          statusData.push(status3)
        }
      }
      return statusData
    },
    isLocationNotExactly() {
      return this.data.distance.id !== 1
    },
    reportStatusIsNotFinish() {
      return !DONE.includes(this.data.status.id)
    },
    isShowRating() {
      return SHOW_RATING_STATUS.includes(this.data.status.id) && this.data.user && String(this.userData.id) === String(this.data.user.id)
    },
    getReportRating() {
      switch (this.data.rating) {
        case 1:
          return {textClass: 'text-success', icon: 'fas fa-laugh'};
          break;
        case 2:
          return {textClass: 'text-secondary', icon: 'fas fa-meh'};
          break;
        case 3:
          return {textClass: 'text-danger', icon: 'fas fa-frown'};
          break;
        default:
          return {textClass: '', icon: ''};
          break
      }
    },
    isStatusVerificationAdminSuccess() {
      return this.data.status.id === 2
    },
    validateUserRole() {
      if (this.data && this.status.length) {
        const {status} = this.data
        const {role} = this.userData

        if (status.id === 1 || status.id === 11) {
          return role.name === UserRole.manager
        }
        if (status.id === 2) {
          return role.name === UserRole.admin
        }
        if (status.id === 4) {
          return role.name === UserRole.superVisor
        }
        if (status.id === 5 || status.id === 7) {
          return role.name === UserRole.dataEntry
        }

        return false
      }
    },
    checkAnyDataFilled() {
      let anyDataFilled = true

      this.spatialData.data.forEach((item) => {
        if (item.file.length && item.caption.length && item.description.length) anyDataFilled = false
      })

      anyDataFilled = !this.spatialData.indication
      anyDataFilled = !this.notes

      return anyDataFilled
    },
    isShowSummaryReport() {
      return VERIFICATION_SUCCESS.includes(this.data.status.id)
    }
  },
  async mounted() {
    await this.getReport()
    await this.getStatus()
    setTimeout(() => {
      this.isShowFormKesimpulan = this.data.status && this.data.status.id === 2
      const statusKesimpulanForm = [4, 5, 6, 7, 8, 9]
      const statusKesimpulanButton = [5, 6, 7, 8, 9]
      this.isShowKesimpulanForm = statusKesimpulanForm.indexOf(this.data.status.id) > -1
      this.isShowKesimpulanButton = statusKesimpulanButton.indexOf(this.data.status.id) > -1
    }, 500)
  },
  watch: {
    '$route.params.id'() {
      this.getReport()
    }
  }
}
</script>

<style scoped lang="scss">
.page-list-container {
  width: 90%;
  margin: auto;
}

.maps-container {
  width: 100%;
  height: 100vh;
  position: relative;
}

.marker-container {
  font-size: 2rem;
}
</style>
