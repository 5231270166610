<template>
  <div>
    <UserHeaderComponent/>
    <router-view></router-view>
    <FooterComponent/>
  </div>
</template>
<script>
import UserHeaderComponent from "../components/UserHeaderComponent";
import FooterComponent from "../../components/general/FooterComponent";

export default {
  name: 'DefaultLayout',
  components: {FooterComponent, UserHeaderComponent}
}
</script>
<style lang="scss" scoped></style>
