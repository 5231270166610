<template>
  <select v-model="selected" class="form-control border-0">
    <option value="">Semua kategori pengaduan</option>
    <option v-for="(item, index) in categoryOptions" :value="item.id">{{ item.name }}</option>
  </select>
</template>

<script>
import {getReportCategory} from "../../service/LocationService";
import CustomSelectComponent from "./CustomSelectComponent";
import {mapState, mapMutations} from "vuex"

export default {
  name: "SelectReportCategoryHomePage",
  components: {CustomSelectComponent},
  props: {
    isCustom: {
      type: Boolean,
      default: true
    }
  },
  async mounted() {
    if (this.reportCategory.length) {
      this.categoryOptions = this.reportCategory
    } else {
      let res = await getReportCategory({})
      this.categoryOptions = res.data
      this.setReportCategory(res.data)
    }

    if(this.query && this.query.categoryId) {
      this.selected = this.query.categoryId
    }
  },
  data() {
    return {
      selected: '',
      categoryOptions: []
    }
  },
  watch: {
    selected(val) {
      this.$router.push({
        name: 'Dashboard',
        query: {
          ...this.query,
          ...{
            categoryId: val
          }
        }
      }).catch((e) => {})
    },

  },
  computed: {
    ...mapState('LocationStore', ['reportCategory']),
    query() {
      return this.$route.query
    }
  },
  methods: {
    ...mapMutations('LocationStore', ['setReportCategory'])
  },
}
</script>

