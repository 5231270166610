<template>
	<div class="min-vh-100 w-50 mx-auto d-flex flex-column justify-content-center">
		<div v-if="!isRequestSent" class="card w-60 p-4 mx-auto bg-white-transparent shadow">
			<div class="card-body">
				<div class="row">
					<div class="col-sm-12 text-center mb-3">
						<DynamicLogoComponent />
					</div>

					<div class="col-sm-12 mb-3">
						<form @submit.prevent="doResetPassword">
							<div class="form-group">
								<CustomInputPillComponent v-model="credentialData.email" id="email" placeholder="Email Pengguna" />
							</div>

							<div class="form-group">
								<button type="submit" class="btn btn-custom-primary rounded-pill w-100">
									Reset Kata Sandi
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>

		<div v-else class="card w-75 p-4 mx-auto bg-white-transparent shadow">
			<div class="card-body">
				<h4 class="text-custom-primary mb-3 font-weight-bold">Reset Kata Sandi Berhasil.</h4>

				<p class="mb-3">
					Silahkan cek email anda.
				</p>

				<router-link :to="{ name: 'Login' }" class="btn btn-custom-primary rounded-pill px-4">
					Kembali
				</router-link>
			</div>
		</div>

		<div v-if="isLoading" class="loading-fullscreen">
			<LoadingComponent />
		</div>
	</div>
</template>

<script>
import {ResetPassword} from "../../../service/AuthService";
import DynamicLogoComponent from "../../../components/general/DynamicLogoComponent";
import CustomInputPillComponent from "../../../components/input/CustomInputPillComponent";
import LoadingComponent from "../../../components/general/LoadingComponent";

export default {
	name: "ResetPasswordComponent",
	components: {LoadingComponent, CustomInputPillComponent, DynamicLogoComponent},
	data() {
		return {
			credentialData: {
				email: ''
			},
			isLoading: false,
			isRequestSent: false
		}
	},
	methods: {
		async doResetPassword() {
			this.isLoading = true

			try {
				const res = await ResetPassword(this.credentialData)

				if (res) {
					this.isRequestSent = true
				}
			} catch (e) {
				this.$store.commit('NotificationStore/openNotification', {
					type: 'error',
					message: e.response.data.message
				})
			}

			this.isLoading = false
		}
	}
}
</script>
