import Http from "../util/Http";

const API_URL = process.env.VUE_APP_API_URL

export const getNotification = () => {
  let url = `${API_URL}/notification`
  return Http.get(url, {});
}

export const updateNotification = (data) => {
  let url = `${API_URL}/notification`
  return Http.post(url, data)
}

export const deleteAllNotification = (data) => {
  let url = `${API_URL}/notification/delete/all`
  return Http.post(url, data)
}

export const deleteNotificationById = (data) => {
  let url = `${API_URL}/notification/delete`
  return Http.post(url, data)
}

export const getUnreadNotification = () => {
  let url = `${API_URL}/notification/unread`
  return Http.get(url, {});
}
