const UserStore = {
    namespaced: true,
    state: {
        userData: null,
        typeSimtaruProfile: false
    },
    getters: {
        isAdmin(state) {
            const adminArray = [
                'ADMINSIMTARU',
                'SUPERADMIN',
                'SUPERVISOR',
                'MANAGER',
                'DATAENTRY'
            ]
            return state.userData && adminArray.indexOf(state.userData.role.name) !== -1
        },
        isSuperAdmin(state) {
            return state.userData && state.userData.role.name === 'SUPERADMIN'
        },
        isPelapor(state) {
            return state.userData && state.userData.role.name === 'PELAPOR'
        },
        isDataManager(state) {
          return state.userData && state.userData.role.name === 'MANAGER'
        }
    },
    mutations: {
        setUserData(state, value) {
            state.userData = value
        },
        setTypeSimtaruProfile(state, value) {
            state.typeSimtaruProfile = value
        }
    },
    actions: {},
}

export default UserStore
