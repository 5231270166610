import { render, staticRenderFns } from "./ModalRatingComponent.vue?vue&type=template&id=3360ad79&scoped=true&"
import script from "./ModalRatingComponent.vue?vue&type=script&lang=js&"
export * from "./ModalRatingComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3360ad79",
  null
  
)

export default component.exports