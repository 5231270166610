<template>
  <div>
    <span
        :class="[classBadge]"
        class="badge rounded-pill">{{ label }}</span>
  </div>
</template>
<script>
const REJECT = [3,6]
const DONE = [8,9]
export default {
  name: 'BadgeStatusComponent',
  props: {
    data: {
      type: Object,
      required: false
    }
  },
  computed: {
    label() {
      if(this.data && this.data.id) {
      	if (this.data.id === 10) return 'Terkirim'
        if(this.data.id === 1) return 'Pending'
        if(REJECT.indexOf(this.data.id) > -1) return 'Ditolak'
        if(DONE.indexOf(this.data.id) > -1) return 'Selesai'
      }
      return 'Diperiksa'
    },
    classBadge() {
      if(this.data && this.data.id) {
	      if (this.data.id === 10) return 'badge-secondary'
	      if(this.data.id === 1) return 'badge-warning'
        if(REJECT.indexOf(this.data.id) > -1) return 'badge-danger'
        if(DONE.indexOf(this.data.id) > -1) return 'badge-success'
      }
      return 'badge-primary'
    }
  }
}
</script>
<style lang="scss" scoped>
.badge {
  padding-left: 15px;
  padding-right: 15px;
}
</style>
