<template>
	<div class="min-vh-100 w-50 mx-auto d-flex flex-column justify-content-center">
		<div class="card w-60 p-4 mx-auto bg-white-transparent shadow">
			<div class="card-body">
				<div class="row">
					<div class="col-sm-12 text-center mb-3">
						<DynamicLogoComponent />
					</div>

					<div class="col-sm-12 mb-3">
						<form @submit.prevent="doLogin">
							<div class="form-group">
								<CustomInputPillComponent v-model="credentialData.credential" id="username" placeholder="Nama Pengguna" />
							</div>

							<div class="form-group">
								<CustomInputPillComponent v-model="credentialData.password" id="password" type="password" placeholder="Kata Sandi" />
							</div>
							<div class="form-group">
								<button type="submit" class="btn btn-custom-primary rounded-pill w-100">
									Masuk
								</button>
							</div>
						</form>
            <hr>
            <div class="social-media-container py-3">
              <FacebookLoginButtonComponent class="mb-3"/>
              <GoogleLoginButtonComponent />
            </div>
					</div>

					<div class="col-sm-12 d-flex justify-content-between">
						<router-link :to="{ name: 'ResetPassword' }">Reset Kata Sandi</router-link>
						<router-link class="text-primary" :to="{ name: 'Register' }" href=""><strong>Daftar</strong></router-link>
					</div>
				</div>
			</div>
		</div>

		<div v-if="isLoading" class="loading-fullscreen">
			<LoadingComponent />
		</div>
	</div>
</template>

<script>
import CustomInputComponent from "../../../components/input/CustomInputComponent";
import CustomInputPillComponent from "../../../components/input/CustomInputPillComponent";
import DynamicLogoComponent from "../../../components/general/DynamicLogoComponent";
import {Login, UserProfile} from "../../../service/AuthService";
import {mapMutations, mapState} from "vuex"
import LoadingComponent from "../../../components/general/LoadingComponent";
import FacebookLoginButtonComponent from "../../../components/FacebookLoginButtonComponent";
import GoogleLoginButtonComponent from "../../../components/GoogleLoginButtonComponent";

const SIMTARU_PROFILE = 'simtaru-profile'

export default {
	name: "UserLoginComponent",
	components: {
    GoogleLoginButtonComponent,
    FacebookLoginButtonComponent,
    LoadingComponent, DynamicLogoComponent, CustomInputPillComponent, CustomInputComponent},
	data() {
		return {
			credentialData: {
				credential: '',
				password: '',
				type: 'web'
			},
			isLoading: false,
      formSimtaru: false
		}
	},
  mounted() {
    const formSimtaruParam = this.$route.query.from
    if (formSimtaruParam === SIMTARU_PROFILE) this.setTypeSimtaruProfile(true)
  },
  methods: {
		...mapMutations('UserStore', ['setUserData', 'setTypeSimtaruProfile']),
		async doLogin() {
			this.isLoading = true

			try {
				const res = await Login(this.credentialData)

				if (res && res.data) {
					localStorage.setItem('accessToken', res.data.token)
					await this.doGetUserData()

          let name = ''
          if (this.typeSimtaruProfile) name = 'EditProfile'
          else name = 'Dashboard'

					await this.$router.push({ name })
				}
			} catch (e) {
				await this.$store.dispatch('NotificationStore/openNotification', {
					message: e.response.data.message,
					type: 'error'
				})
			}

			this.isLoading = false
		},
		async doGetUserData() {
			try {
				const res = await UserProfile()

				if (res && res.data) {
					this.setUserData(res.data)
				}
			} catch (e) {
				await this.$store.dispatch('NotificationStore/openNotification', {
					message: e.response.data.message,
					type: 'error'
				})
			}
		}
	},
  computed: {
    ...mapState('UserStore', ['typeSimtaruProfile'])
  }
}
</script>

<style scoped lang="scss">
	.login-container {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
	}
</style>
