<template>
	<transition name="fade">
		<slot></slot>
	</transition>
</template>

<script>
export default {
	name: "FadeTransitionComponent"
}
</script>
