<template>
  <div class="text-left">
    <small>Hasil Verifikasi Spatial</small>
    <div v-for="(item, index) in spatialData" :key="index"
         :class="[ index + 1 === spatialData.length ? '' : 'border-bottom']"
         class="py-3 mb-3">
      <div class="form-group">
        <UploadComponent
            style="height: 420px; overflow: hidden"
            :is-no-background="true"
            :images="item.file"
            :is-no-button="isFileExists"
            @uploaded="item.file = $event"
            placeholder="Upload"
            :is-read-only="readonly"
        />
        <small>*) Maximum file 2Mb, format .jpg, .png</small>
      </div>
      <div class="form-group">
        <CustomInputComponent
            :read-only="readonly"
            v-model="item.caption" placeholder="Judul Gambar"/>
      </div>
      <div class="form-group">
        <CustomTextareaComponent
            :read-only="readonly"
            v-model="item.description" placeholder="Deskripsi"/>
      </div>
      <div
          v-if="index !== 0 && !readonly"
          @click="removeImage(index)"
          class="form-group text-right">
        <button class="btn rounded-pill btn-sm btn-danger">
          Hapus
        </button>
      </div>
    </div>
    <button
        v-if="!readonly"
        @click="addImage()"
        class="btn btn-primary btn-sm rounded-pill">
      Tambah Gambar
    </button>
    <hr>
    <div class="row">
      <div class="col-12">
        <div class="">
          <div class="form-check">
            <input v-model="indication"
                   :disabled="readonly"
                   class="form-check-input" name="indication" type="radio" id="indicationY"
                   :value="true">
            <label class="form-check-label" for="indicationY">
              Terindikasi adanya pelanggaran pemanfaatan ruang
            </label>
          </div>

          <div class="form-check">
            <input
                :disabled="readonly"
                v-model="indication" class="form-check-input" name="indication" type="radio" id="indicationN"
                   :value="false">
            <label class="form-check-label" for="indicationN">
              Tidak Terindikasi adanya pelanggaran pemanfaatan ruang
            </label>
          </div>
        </div>
      </div>
    </div>
    <hr>
  </div>
</template>

<script>
import UploadComponent from "../../../../components/general/UploadComponent";
import CustomInputComponent from "../../../../components/input/CustomInputComponent";
import CustomTextareaComponent from "../../../../components/input/CustomTextareaComponent";

const defaultSpatialData = () => {
  return {
    file: [],
    caption: '',
    description: ''
  }
}

export default {
  name: "ReportSpatialVerificationComponent",
  components: {CustomTextareaComponent, CustomInputComponent, UploadComponent},
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    existingData: {
      type: Array,
      default: () => {
        return []
      }
    },
    indikasi: {
      type:Boolean,
      default: ''
    }
  },
  data() {
    return {
      spatialData: [],
      indication: this.existingData ? this.indikasi : ''
    }
  },
  computed: {
    isFileExists() {
      let status = false
      if(this.existingData) {
        this.existingData.forEach((v) => {
          if(v.file.length && v.file[0].url) status = true
        })
      }
      return status
    }
  },
  methods: {
    handleFileUpload(data, index) {
      let newUpdatedData = [...this.spatialData]
      newUpdatedData[index].file.push(data)
      this.spatialData = newUpdatedData
    },
    removeImage(key) {
      this.spatialData.splice(key, 1)
    },
    addImage() {
      this.spatialData.push(defaultSpatialData())
    }
  },
  watch: {
    spatialData: {
      handler(val) {
        this.$emit('input', {data: this.spatialData, indication: this.indication})
      },
      deep: true
    },
    indication() {
      this.$emit('input', {data: this.spatialData, indication: this.indication})
    }
  },
  mounted() {
    if (this.existingData && this.existingData.length) {
      this.spatialData = this.existingData.map((v) => {
        v.file = [v.file]
        return v
      })
    } else {
      for (let i = 0; i < 4; i++) {
        this.spatialData.push(defaultSpatialData())
      }
    }
  }
}
</script>

<style scoped>

</style>
