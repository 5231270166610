<template>
	<div>
		<FadeTransitionComponent>
			<div
				v-if="isShow"
				@click.self="handleClickBg"
				class="modal fade show in p-3" tabindex="-1" role="dialog">
				<div
					:class="[
					isScrollable ? 'modal-dialog-scrollable' : '',
					isCenter ? 'modal-dialog-centered' : '',
					size,
					modalClass
				  ]"
					class="modal-dialog" role="document">
					<div class="modal-content">
						<div
							v-if="isHeader"
							:class="[headerClass]"
							class="modal-header">
							<h5 class="modal-title">
								{{ title ? title : '' }}
							</h5>
							<button
								@click="$emit('close')"
								type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<slot name="body"></slot>
						</div>
						<div
							v-if="isFooter"
							class="modal-footer">
							<slot name="footer"></slot>
						</div>
					</div>
				</div>
			</div>
		</FadeTransitionComponent>
	</div>
</template>
<script>
import FadeTransitionComponent from "../FadeTransitionComponent";

export default {
	name: 'ModalComponent',
	components: {FadeTransitionComponent},
	props: {
		modalClass: {
			type: String,
			default: ''
		},
		headerClass: {
			type: String,
			default: ''
		},
		isShow: {
			type: Boolean,
			default: false
		},
		isFooter: {
			type: Boolean,
			default: false
		},
		isHeader: {
			type: Boolean,
			default: false
		},
		title: {
			type: String,
			default: ''
		},
		isScrollable: {
			type: Boolean,
			default: false
		},
		isCenter: {
			type: Boolean,
			default: false
		},
		size: {
			type: String,
			default: ''
		}
	},
	methods: {
		handleClickBg() {
			this.$emit('close')
		}
	}
}
</script>
<style lang="scss" scoped>
.modal {
	display: block;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: rgba(0, 0, 0, 0.5);
}
</style>
