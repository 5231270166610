<template>
  <div class="custom-form-container" :class="(iconUnicode.length ? 'input-icon ' : '') + addClass">
    <label v-if="value">{{ placeholder }}</label>
    <div v-if="readOnly">
      {{ value }}
    </div>
    <textarea
        v-if="!readOnly"
        @input="$emit('input', $event.target.value)" :type="type"
        :value="value"
        :readonly="readOnly"
        :required="isRequired" class="custom-form-input"
        :style="iconUnicode"
        cols="4"
        :placeholder="placeholder"></textarea>
  </div>
</template>

<script>
export default {
  name: "CustomTextareaComponent",
  props: {
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    value: {
      type: [Number, String],
      default: ''
    },
    id: {
      type: String,
      required: false,
      default: ''
    },
    iconUnicode: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    addClass: {
      type: String,
      required: false,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getClass() {
      return {}
    }
  }
}
</script>

<style scoped lang="scss">
.input-icon {
  position: relative;

  &:before {
    font-family: "Font Awesome 5 Free";
    content: "\f002";
    font-weight: 900;
    position: absolute;
    width: 50px;
    height: 50px;
    z-index: 1;
    right: 0;
    bottom: -14px;
  }
}

.custom-form-input {
  width: 100%;
  border: 0;
  padding: 10px 0;
  border-bottom: 1px solid #A4A4A4;

  &:focus {
    outline: none;
    background-color: #fafafa;
    border: 0;
    border-bottom: 1px solid #A4A4A4;
  }

}

label {
  font-size: 0.8rem;
  margin-bottom: 0;
  transition: 0.3s ease all;
  -moz-transition: 0.3s ease all;
  -webkit-transition: 0.3s ease all;
}
</style>
