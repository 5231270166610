<template>
  <div class="h-100 position-relative">
    <div class="dashboard-container w-100 position-relative">
      <SlideAnimationComponent>
        <SidebarComponent v-if="isShowSidebar"
                          @report-sent="reportIsSent"
                          @close="closeSidebar"
        />
      </SlideAnimationComponent>

      <div class="h-100 w-100 position-relative">
        <GoogleMapsComponent :key="renderMap"/>

        <div class="btn-home-container">
          <div class="row">
            <div class="col-sm-6 px-0 d-flex justify-content-start">
              <button v-if="!isShowSidebar" @click="openSidebar" class="btn btn-home border border-white rounded-pill">
                Pengaduan Terbaru
                <span><i class="fas fa-long-arrow-alt-right ml-3"></i></span>
              </button>
            </div>

            <div class="col-sm-6 px-0">
              <div class="form-group row">
                <div class="col-sm-4">
                  <div class="px-3 rounded-pill bg-white">
                    <SelectReportCategoryHomePage
                        v-if="!isCreateReport"/>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="input-with-icon">
                    <GmapAutocomplete @place_changed="handleMapChange" class="form-control rounded-pill"/>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="px-3 rounded-pill bg-white">
                    <LayerSelectorComponent/>
                  </div>
                  <LayerLegendComponent/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ReportStatisticComponent v-if="!isAdmin"/>
    <ReportStatisticAdminComponent v-else/>
  </div>
</template>

<script>
import UserHeaderComponent from "../components/UserHeaderComponent";
import GoogleMapsComponent from "../../components/GoogleMapsComponent";
import SidebarComponent from "../components/sidebar/SidebarComponent";
import SlideAnimationComponent from "../../components/SlideAnimationComponent";
import {mapState, mapMutations, mapGetters} from "vuex"
import SelectReportCategoryComponent from "../../components/input/SelectReportCategoryComponent";
import SelectReportCategoryHomePage from "../../components/input/SelectReportCategoryHomePage";
import ReportStatisticComponent from "../components/ReportStatisticComponent";
import ReportStatisticAdminComponent from "../components/ReportStatisticAdminComponent";
import LayerSelectorComponent from "../components/dashboard/LayerSelectorComponent";
import LayerLegendComponent from "../components/dashboard/LayerLegendComponent";

export default {
  name: "DashboardScreen",
  components: {
    LayerSelectorComponent,
    ReportStatisticAdminComponent,
    ReportStatisticComponent,
    SelectReportCategoryHomePage,
    SelectReportCategoryComponent,
    SlideAnimationComponent, SidebarComponent, GoogleMapsComponent, UserHeaderComponent,
    LayerLegendComponent
  },
  data() {
    return {
      isOpenCreateReport: false,
      renderKey: 1,
      googleAutocomplete: null,
      filterCategory: null,
      renderMap: 1
    }
  },
  computed: {
    ...mapState('SidebarStore', ['isShowSidebar', 'isCreateReport']),
    ...mapState('ReportStore', ['statisticReport']),
    ...mapGetters('UserStore', ['isAdmin'])
  },
  methods: {
    ...mapMutations('SidebarStore', ['toggleSidebar']),
    ...mapMutations('MapsStore', ['setDefaultCoordinate', 'setFilterCategory']),
    ...mapMutations('ReportStore', ['setStatisticReport']),
    openSidebar() {
      this.$store.commit('SidebarStore/toggleOpenList', true)
    },
    reportIsSent() {
      this.isShowSidebar = true
      this.isOpenCreateReport = false
      this.renderKey += 1
    },
    closeSidebar() {
      this.isShowSidebar = false
      this.isOpenCreateReport = false
    },
    handleMapChange(data) {
      if (data && data.geometry) {
        this.setDefaultCoordinate({
          lat: data.geometry.location.lat(),
          lng: data.geometry.location.lng()
        })
      }
    },

  },
  watch: {
    filterCategory() {
      this.setFilterCategory(this.filterCategory.id)
      this.renderMap += 1
    }
  }
}
</script>

<style scoped lang="scss">
body {
  height: 100%;
}

p {
  margin-bottom: 0;
}

.btn-home-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 40px 70px;
}

.btn-home {
  background: rgba(41, 87, 164, 0.8) !important;
  color: white !important;
  font-weight: bold;
  height: 40px;
  padding-right: 1rem;
  padding-left: 1rem;
}


.dashboard-container {
  height: 92vh;
}
</style>
