<template>
	<div class="update-modal-container">
		<div
			v-if="!isLoading"
			class="col-12 d-flex justify-content-start">
			<div class="card">
				<div class="card-body">
					<div class="text-left">
						<small><strong>Lokasi Pelaporan</strong></small>

						<div class="d-flex justify-content-start align-items-center mt-3">
							<h4 class="mr-3">
								<i class="fa fa-map-marker-alt"></i>
							</h4>
							<p class="text-left">
								{{ `${data.distance.name} arah ${data.direction.name} dari` }} <br>
								{{ `${data.latitude} ${data.longitude}` }} <br>
								{{ data.location }}
							</p>
						</div>
						<hr>
					</div>

					<form @submit.prevent="sendUpdateLocation">
						<div class="text-left">
							<small><strong>Pembaruan Lokasi</strong></small>

							<div class="d-flex justify-content-start align-items-center mt-3">
								<h4 class="mr-3">
									<i class="fa fa-map-marker-alt"></i>
								</h4>
								<p class="text-left" v-if="currentContext">
									{{ `${currentContext.geometry.location.lat} ${currentContext.geometry.location.lng}` }} <br>
									{{ currentContext.formatted_address }}
								</p>

								<p class="text-left" v-else>
									<em>Pilih Lokasi</em>
								</p>
							</div>
						</div>

						<div class="text-right mt-3">
							<button @click.prevent="closeUpdateLocation" class="btn btn-outline-secondary rounded-pill px-3 mr-2">
								<span><i class="fa fa-long-arrow-alt-left mr-3"></i></span>
								<strong>Kembali</strong>
							</button>

							<button type="submit" class="btn btn-yellow rounded-pill px-3" :disabled="!updateState.data.location.length">
								<strong>Perbarui Lokasi</strong>
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
		<div v-if="isLoading" class="loading-fullscreen">
			<LoadingComponent/>
		</div>
	</div>
</template>

<script>
import {mapState, mapMutations} from "vuex"
import {updateReport} from "../../../../service/ReportService";
import LoadingComponent from "../../../../components/general/LoadingComponent";

export default {
	name: "UpdateLocationComponent",
	components: {LoadingComponent},
	props: {
		data: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			isLoading: false
		}
	},
	computed: {
		...mapState('MapsStore', ['currentContext']),
		...mapState('ReportStore', ['updateState']),
		...mapState('UserStore', ['userData'])
	},
	methods: {
		...mapMutations('ReportStore', ['setUpdateState', 'resetUpdateState', 'setTemporaryMarker']),
		...mapMutations('MapsStore', ['resetContext']),
		closeUpdateLocation() {
			this.resetUpdateState()
			this.resetContext()
		},
		async sendUpdateLocation() {
			this.isLoading = true
			const payload = {...this.data}

			payload.longitude = this.updateState.data.longitude
			payload.latitude = this.updateState.data.latitude
			payload.location = this.updateState.data.location
			payload.distance = {
				id: 1,
				name: 'Tepat di Lokasi'
			}
			payload.direction = null

			try {
				let res = await updateReport(payload)

				if (res && res.data) {
					this.$helper.errorNotification('Berhasil memperbarui lokasi.', 'success')
					this.$emit('update-success', true)
				}
			} catch (e) {
				let message = e.response.data.message ? e.response.data.message : 'Error.'

				this.$helper.errorNotification(message, 'error')
			}

			this.isLoading = false
		}
	},
	watch: {
		currentContext() {
			this.updateState.data.latitude = this.currentContext.geometry.location.lat
			this.updateState.data.longitude = this.currentContext.geometry.location.lng
			this.updateState.data.location = this.currentContext.formatted_address
		}
	},
	beforeDestroy() {
		this.resetUpdateState()
		this.resetContext()
		this.setTemporaryMarker([])
	}
}
</script>

<style scoped>
.update-modal-container {
	width: 450px;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	margin: 40px 70px;
	z-index: 1;
}
</style>
