<template>
  <ModalComponent
      @close="$emit('close')"
      :is-show="true"
      :is-scrollable="true"
      :size="`modal-xl`"
      :is-header="true"
      :is-footer="true"
      :title="`Preview`">
    <template v-slot:body>
      <div id="print-container" class="text-left print-settings">
        <div style="float: left; width: 50%">
	        <div class="w-100 d-flex justify-content-between align-items-center mb-2">
		        <div class="text-left">
			        <small>Laporan</small>
			        <p><strong>{{ data.reportType ? data.reportType.name : '-' }}</strong></p>
		        </div>

		        <div class="d-flex">
			        <div v-if="data.rating" class="d-flex">
				        <div :class="getReportRating.textClass">
					        <h3><i class="mr-3" :class="getReportRating.icon"></i></h3>
				        </div>
			        </div>
		        </div>
	        </div>
	        <div class="text-left mb-3">
		        <small>Kategori Pelaporan</small>
		        <p>{{ data.reportCategory ? data.reportCategory.name : '-' }}</p>
	        </div>
	        <div class="text-left mb-3">
		        <small>Deskripsi</small>
		        <div>
			        {{ data.description }}
		        </div>
	        </div>
	        <hr>
	        <div class="text-left">
		        <div v-if="data.oldLocation">
			        <small>Lokasi Terkoreksi</small>

			        <div class="row m-0 mt-3">
				        <div class="col-auto pl-0 marker-container">
					        <i class="fa fa-map-marker-alt"></i>
				        </div>
				        <div class="col-11">
					        <p>
						        {{ data.latitude }} , {{ data.longitude }} <br/>
						        {{ data.location }}
					        </p>
				        </div>
			        </div>
		        </div>

		        <small>Lokasi Pelaporan</small>

		        <div v-if="data.oldLocation" class="row m-0 mt-3">
			        <div class="col-auto pl-0 marker-container">
				        <i class="fa fa-map-marker-alt"></i>
			        </div>
			        <div class="col-11">
				        <p>
					        {{
						        `${data.oldLocation.distance.name} arah ${data.oldLocation.direction ? data.oldLocation.direction.name : ''} dari`
					        }} <br>
					        {{ data.oldLocation.latitude }} , {{ data.oldLocation.longitude }} <br/>
					        {{ data.oldLocation.location }}
				        </p>
			        </div>
		        </div>
		        <div v-else class="row m-0 mt-3">
			        <div class="col-auto pl-0 marker-container">
				        <i class="fa fa-map-marker-alt"></i>
			        </div>
			        <div class="col-11">
				        <p>
					        {{ `${data.distance.name} arah ${data.direction ? data.direction.name : ''} dari` }} <br>
					        {{ data.latitude }} , {{ data.longitude }} <br/>
					        {{ data.location }}
				        </p>
			        </div>
		        </div>
		        <div>
			        <div class="row m-0">
				        <div class="col-9 p-0">
					        <p>
						        Provinsi {{ data.province.name }}, Kabupaten {{ data.regencies.name }}, <br/>
						        Distrik {{ data.district.name }}, Kampung {{ data.villages.name }}
					        </p>
				        </div>
			        </div>
		        </div>
	        </div>
	        <hr>
	        <div v-if="isAdmin" class="text-left">
		        <small>
			        Nama Pelapor
		        </small>
		        <p>
			        {{ data.user.fullname }}
		        </p>
		        <small>
			        Institusi
		        </small>
		        <p>
			        {{ data.institusi && data.institusi.name ? data.institusi.name : '-' }}
			        <br>
			        {{ data.institusiName }}
			        <br>
			        {{ data.address }}
		        </p>
		        <small>
			        Nomor Ponsel sewaktu pelaporan
		        </small>
		        <p>
			        {{ data.mobile ? $helper.revertPhoneNumber(data.mobile) : '-' }}
		        </p>
		        <small>
			        Nomor Ponsel terbaru
		        </small>
		        <p>
			        {{ $helper.revertPhoneNumber(data.user.mobile) }}
		        </p>
		        <small>
			        Email terbaru
		        </small>
		        <p>
			        {{ data.user.email }}
		        </p>
		        <hr>
	        </div>
	        <div class="text-left">
		        <div v-if="!isAdmin" class="mb-2">
			        <small>Status</small>
			        <p class="font-weight-bold">Laporan sedang ditangani pihak terkait</p>
		        </div>
		        <div v-else class="mb-3">
			        <small>
				        Status Terakhir
			        </small>
		        </div>
		        <ReportStatusItemComponent
			        v-for="val in data.reportStatus"
			        :data="val"
			        :key="val.id"
		        />
	        </div>
        </div>
        <div style="float: left; width: 50%">
	        <small>
		        Gambar
	        </small>
	        <div
		        v-for="(item, index) in data.images"
		        :key="index"
		        class="mt-3 mb-3">
		        <img :src="item.url" class="img-fluid" alt="">
	        </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="text-center">
        <button @click="printDetail" class="btn btn-primary btn-sm rounded-pill px-3 font-weight-bold ml-3">
          <span><i class="fas fa-print mr-2"></i></span>
          Print Detail
        </button>
      </div>
    </template>
  </ModalComponent>
</template>
<script>
import ModalComponent from "../../../../components/general/ModalComponent";
import {mapGetters} from "vuex";
import ReportStatusItemComponent from "./ReportStatusItemComponent";

export default {
  name: 'ModalPrintPreviewComponent',
  components: {ReportStatusItemComponent, ModalComponent},
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('UserStore', ['isAdmin']),
	  getReportRating() {
		  switch (this.data.rating) {
			  case 1: return { textClass: 'text-success', icon: 'fas fa-laugh' };
				  break;
			  case 2: return { textClass: 'text-secondary', icon: 'fas fa-meh' };
				  break;
			  case 3: return { textClass: 'text-danger', icon: 'fas fa-frown' };
				  break;
			  default: return { textClass: '', icon: '' };
				  break
		  }
	  },
  },
  methods: {
    async printDetail() {
      try {

        this.$htmlToPaper('print-container');
      } catch (e) {
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@media print {
	.print-settings {
		-moz-column-count: 2;
		-webkit-column-count: 2;
		column-count: 2;
		-webkit-column-break-inside: avoid;
		page-break-inside: avoid;
		break-inside: avoid;
	}
}
</style>
