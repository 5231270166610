import Vue from 'vue'
import Vuex from 'vuex'
import UserStore from "./user_store";
import NotificationStore from "./notification_store";
import ReportStore from "./report_store";
import MapsStore from "./maps_store"
import SidebarStore from "./sidebar_store";
import LocationStore from "./location_store";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    ReportStore,
    UserStore,
    NotificationStore,
    MapsStore,
    SidebarStore,
    LocationStore
  }
})
