<template>
	<div :class="(iconUnicode.length ? 'input-icon ' : '') + addClass + (isWithLabel ? 'custom-with-label' : '')">
		<span v-if="isWithLabel">{{ label }}</span>

		<input v-if="!isWithLabel"
		       @input="$emit('input', $event.target.value)"
		       :type="type"
		       class="form-control rounded-pill"
		       :style="iconUnicode"
		       :placeholder="placeholder"
		       :required="isRequired"
		       :value="value"
		       :minlength="minLength">

		<input v-else
		       @input="$emit('input', $event.target.value)"
		       :type="type"
		       class="form-control rounded-pill"
		       :style="iconUnicode"
		       :placeholder="placeholder"
		       :required="isRequired"
		       :value="value"
		       :minlength="minLength">
	</div>
</template>

<script>
export default {
	name: "CustomInputPillComponent",
	props: {
		type: {
			type: String,
			required: false,
			default: 'text'
		},
		value: {
			type: String,
			required: false,
			default: ''
		},
		id: {
			type: String,
			required: false,
			default: ''
		},
		iconUnicode: {
			type: String,
			required: false,
			default: ''
		},
		placeholder: {
			type: String,
			required: false,
			default: ''
		},
		addClass: {
			type: String,
			required: false,
			default: ''
		},
		isRequired: {
			type: Boolean,
			required: false,
			default: true
		},
		isWithLabel: {
			type: Boolean,
			required: false,
			default: false
		},
		label: {
			type: String,
			required: false,
			default: ''
		},
		minLength: {
			type: Number,
			required: false,
			default: 0
		}
	},
	computed: {
		getClass() {
			return {

			}
		}
	}
}
</script>

<style scoped lang="scss">
	.input-icon {
		position: relative;

		&:before {
			font-family: "Font Awesome 5 Free";
			content: "\f002";
			font-weight: 900;
			position: absolute;
			width: 50px;
			height: 50px;
			z-index: 1;
			right: 0;
			bottom: -14px;
		}
	}

	.custom-with-label {
		position: relative;

		span {
			position: absolute;
			z-index: 10;
			left: 16px;
			bottom: 7px;
			width: 180px;
			text-align: left;
			border-right: 1px solid #ced4da;
		}

		input {
			padding-left: 210px;
		}
	}
</style>
