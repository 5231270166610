<template>
  <div
      :class="[isAdmin ? 'admin' : '']"
      class="dropdown-notification-container card shadow">
    <div class="card-header">
      <div class="row">
        <div class="col text-left">
          Notifikasi
        </div>
        <div class="col text-right">
          <small><i @click="deleteNotification()" class="fa fa-trash"></i></small>
        </div>
      </div>
    </div>
    <ul
        v-if="notification.length"
        class="list-group list-group-flush">
        <NotificationItemComponent
          v-for="(val, key) in notification"
          :key="key"
          :data="val"
        />
    </ul>
    <div
        v-if="!notification.length"
        class="card-body">
      <span class="text-white">
        Tidak ada Notifikasi
      </span>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {deleteAllNotification, getNotification} from "../../../service/NotificationService";
import NotificationItemComponent from "./NotificationItemComponent";

export default {
  name: 'DropDownNotificationComponent',
  components: {NotificationItemComponent},
  data() {
    return {
      notification: [],
      limit: 10,
      offset: 0
    }
  },
  computed: {
    ...mapGetters('UserStore', ['isAdmin']),
  },
  methods: {
    async getAllNotification() {
      try {
        const parameter = {
          filter: {
            take: this.limit,
            skip: this.offset
          }
        }
        const res = await getNotification(parameter)
        if(res && res.data && res.data.data.length) {
          this.offset += this.limit
          this.notification = [...this.notification, ...res.data.data]
        }
      } catch (e) {
        const message = e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message
        this.$helper.errorNotification(message)
      }
    },
    async deleteNotification() {
      await deleteAllNotification({})
      this.notification = []
    }
  },
  mounted() {
    this.getAllNotification()
  }
}
</script>
<style scoped>
.fa {
  cursor: pointer;
}
</style>
