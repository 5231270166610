<template>
  <div
      v-if="activeLayer"
      class="card legend-container position-absolute">
    <div class="card-body text-left">
      <img :src="activeLayer.label"
           class="img-fluid"
           alt="">
    </div>
  </div>
</template>
<script>
import {mapState} from "vuex";

export default {
  name: 'LayerLegendComponent',
  computed: {
    ...mapState('MapsStore', ['activeLayer'])
  }
}
</script>
<style lang="scss" scoped>
.card {
  border-radius: 15px;
  top: 140%;
  min-width: 90%;
}
.card-body {
  max-height: 350px;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
