const defaultCoordinate = () => {
  return {lat: -4.185235, lng: 136.825284}
}

const MapsStore = {
  namespaced: true,
  state: {
    currentContext: null,
    defaultCoordinate: defaultCoordinate(),
    filterCategory: null,
    isAllowCreateMarker: false,
    layerOptions: [],
    activeLayer: null
  },
  mutations: {
    setLayerOptions(state, value) {
      state.layerOptions = value
    },
    setActiveLayer(state, value) {
        state.activeLayer = value
    },
    setContext(state, value) {
      state.currentContext = value
    },
    setDefaultCoordinate(state, value) {
      state.defaultCoordinate = {...value}
    },
    setFilterCategory(state, value) {
      state.filterCategory = value
    },
    resetDefaultCoordinate(state) {
      state.defaultCoordinate = defaultCoordinate()
    },
    setAllowCreateMarker(state, value) {
      state.isAllowCreateMarker = value
    },
    resetContext(state) {
      state.currentContext = null
    }
  }
}

export default MapsStore
