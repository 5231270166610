<template>
	<router-link :to="{ name: 'ReportDetail', params: { id: item.id } }">
		<div class="row mb-3">
			<div class="col-sm-4">
				<div
					v-if="images"
					:style="{backgroundImage: `url(${images})`, paddingBottom: `50%`}"
					class="standard-thumbnail">
				</div>
			</div>
			<div class="col-sm-8">
				<div class="w-100 d-flex justify-content-between">
					<div class="text-left">
						<small>{{ $helper.formatDate(item.createAt) }}</small>
						<p>{{ item.reportCategory ? item.reportCategory.name : '-' }}</p>
					</div>


					<div class="d-flex align-items-center">
						<ReportRatingComponent v-if="item.rating && isShowRating" :id="item.id" :show-label="false" :rating="item.rating" />
						<BadgeStatusComponent :data="item.status" class="ml-3" />
					</div>
				</div>

				<div class="text-left">
					<p><strong>{{ item.reportType ? item.reportType.name : '-' }}</strong></p>

					<div class="text-left">
						<div class="row m-0">
							<div class="col-1 d-flex justify-content-center align-items-start text-center marker-container">
								<i class="fa fa-map-marker-alt"></i>
							</div>
							<div class="col-11">
								<p>
									{{ item.latitude }} , {{ item.longitude }} <br/>
									{{ item.location }}
								</p>
							</div>
						</div>
					</div>
					<p>
						{{ item.distance.name }} , {{ item.direction ? item.direction.name : '-' }} <br>
						{{ item.province.name }} - {{ item.regencies.name }} <br/>
						{{ item.district.name }} - {{ item.villages.name }}
					</p>
				</div>
				<hr>
			</div>
		</div>
	</router-link>
</template>

<script>
import BadgeStatusComponent from "../../components/BadgeStatusComponent";
import ReportRatingComponent from "../screen/reports/components/ReportRatingComponent";
import {mapState} from "vuex"

export default {
	name: "ReportListRowComponent",
	components: {ReportRatingComponent, BadgeStatusComponent},
	props: {
		item: {
			type: Object,
			required: true
		}
	},
	computed: {
		...mapState('UserStore', ['userData']),
		images() {
			if(this.item.images.length) return this.item.images[0].url
			return require('../../assets/images/placeholder-image.png')
		},
		isShowRating() {
			return this.item.user && String(this.userData.id) === String(this.item.user.id)
		}
	}
}
</script>

<style scoped>
.marker-container {
	font-size: 2rem;
}
</style>
