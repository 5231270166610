import Http from "../util/Http";

const API_URL = process.env.VUE_APP_API_URL

export const getProvince = (data) => {
    let url = `${API_URL}/master/province`
    return Http.get(url, data);
}

export const getKabupaten = (data, id) => {
    let url = `${API_URL}/master/kabupaten/${id}`
    return Http.get(url, data);
}

export const getKecamatan = (data,id) => {
    let url = `${API_URL}/master/kecamatan/${id}`
    return Http.get(url, data);
}

export const getDesa = (data,id) => {
    let url = `${API_URL}/master/desa/${id}`
    return Http.get(url, data);
}

export const getInstitusi = (data) => {
    let url = `${API_URL}/master/institusi`
    return Http.get(url, data);
}

export const getReportCategory = (data) => {
    let url = `${API_URL}/master/report/category`
    return Http.get(url, data);
}

export const getReportType = (data, id) => {
    let url = `${API_URL}/master/report/type/${id}`
    return Http.get(url, data);
}

export const getDistance = (data) => {
    let url = `${API_URL}/master/distance`
    return Http.get(url, data);
}

export const getDirection = (data) => {
    let url = `${API_URL}/master/direction`
    return Http.get(url, data);
}

export const getStatus = (data) => {
    let url = `${API_URL}/master/report/status`
    return Http.get(url, data);
}

export const getRoles = () => {
  return Http.get(`${API_URL}/master/user/roles`, {})
}

