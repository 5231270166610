import Http from "../util/Http";

class GoogleMapService {
    constructor() {
        this.api_key = process.env.VUE_APP_GOOGLE_APIKEY;
        this.url = 'https://maps.googleapis.com/maps/api/geocode/json';
        this.placesUrl =
            'https://maps.googleapis.com/maps/api/place/autocomplete/json';
    }

    reverseGeoCoding = (lat, long) => {
        const params = {
            urlParams: `key=${this.api_key}&address=${lat},${long}`,
        };

        let url = `${this.url}?${params.urlParams}`;

        return Http.getGoogleMaps(url, params, false);
    };

    geoAdress = address => {
        const params = {
            urlParams: `key=${this.api_key}&address=${address}`,
        };
        let url = `${this.url}?${params.urlParams}`;
        return Http.getGoogleMaps(url, params);
    };

    googleSuggestion = address => {
        const params = {
            urlParams: `key=${this.api_key}&input=${address}`,
        };
        let url = `${this.placesUrl}?${params.urlParams}`;
        return Http.getGoogleMaps(url, params);
    };

}

export default new GoogleMapService();
