import Http from "../util/Http";

const API_URL = process.env.VUE_APP_API_URL

export const statisticReport = (data) => {
  return Http.get(`${API_URL}/report/statistic`, data)
}

export const statisticAdminReport = (data) => {
  return Http.get(`${API_URL}/report/statistic/legend`, data)
}

export const createReport = data => {
  return Http.post(`${API_URL}/report`, data)
}

export const findMyReports = data => {
  return Http.get(`${API_URL}/report/self`, data)
}

export const findReportById = (id) => {
  return Http.get(`${API_URL}/report/${id}`, {})
}

export const getReport = (data) => {
  return Http.get(`${API_URL}/report`, data)
}

export const updateReport = (data) => {
  return Http.post(`${API_URL}/report`, data)
}

export const sendRating = (data) => {
  return Http.post(`${API_URL}/report/rating`, data)
}

export const getLayerMaps = () => {
  return Http.get(`${API_URL}/master/wms`, {})
}

export const createSpatialVerification = (data) => {
  return Http.post(`${API_URL}/report/verify/spatial`, data)
}