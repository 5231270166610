<template>
  <CustomSelectComponent
      :is-required="true"
      @input="handleInput" :value="value" :options="options" placeholder="Level"/>
</template>

<script>
import {getRoles} from "../../service/LocationService";
import CustomSelectComponent from "./CustomSelectComponent";
import {mapState, mapMutations} from "vuex"

export default {
  name: "SelectRolesComponent",
  components: {CustomSelectComponent},
  props: {
    value: {
      type: [Number, String],
      default: ''
    }
  },
  async mounted() {
    await this.getData()
  },
  data() {
    return {
      options: []
    }
  },
  methods: {
    ...mapMutations('LocationStore', ['setRoles']),
    async getData() {
      try {
        if (this.roles.length) {
          this.options = this.roles
        } else {
          let res = await getRoles()
          this.options = res.data
        }
      }catch (e) {
        const message = e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message
        this.$helper.errorNotification(message)
      }

    },
    handleInput(e) {
      this.$emit('input', this.options.find(item => {
        return String(item.id) === String(e)
      }))
    }
  },
  watch: {
    districtId() {
      this.getData()
    }
  },
  computed: {
    ...mapState('LocationStore', ['roles'])
  }
}
</script>

<style scoped>

</style>
